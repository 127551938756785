import React, { useEffect, useState } from "react";

interface Props {
  children: React.ReactNode;
  delay: number;
}

const DelayedLoading: React.FC<Props> = ({ children, delay }) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setShouldRender(true);
    }, delay);

    return () => clearTimeout(timeOut);
  });

  if (!shouldRender) {
    return null;
  }

  return <>{children}</>;
};
export default DelayedLoading;
