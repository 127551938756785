import { getApiUrl } from "api/config";
import { useGetImmediatelyRequest } from "api/hooks";
import { RequestStatus } from "api/httpRequest";
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Station, StationMap } from "../Types";
import { getCurrentCountryCode } from "../../environment";

export type StationsContextType = {
  stations: StationMap;
  getStationsStatus: RequestStatus<Station[]>;
};

export const StationsContext = createContext<StationsContextType | null>(null);

interface Props {
  children: ReactNode;
}

export const StationsProvider: React.FC<Props> = ({ children }) => {
  const { getRequestStatus: getStationsStatus } = useGetImmediatelyRequest<Station[]>(
    `${getApiUrl()}/stations/${getCurrentCountryCode()}`
  );

  const [stations, setStations] = useState<StationMap>(new Map());

  useEffect(() => {
    if (getStationsStatus.status === "success") {
      const data = getStationsStatus.data || [];
      setStations(new Map(data.map(station => [station.jbvId, station.name])));
    }
  }, [getStationsStatus.status]);

  return (
    <StationsContext.Provider
      value={{
        stations,
        getStationsStatus,
      }}
    >
      {children}
    </StationsContext.Provider>
  );
};

export const useStations = () => {
  const context = useContext(StationsContext);

  if (!context) {
    throw new Error("Du er utenfor Stations-konteksten!");
  }

  return context;
};
