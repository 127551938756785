import { Flex } from "@vygruppen/spor-react";
import styled from "@emotion/styled";

export const ContentContainer = styled(Flex)`
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  width: min(90%, 1200px);
  min-height: 100%;
`;
