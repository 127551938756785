import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@vygruppen/spor-react";
import tokens from "@vygruppen/spor-design-tokens";
import trashCan from "../../shared/assets/soppelbotte.svg";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteRequest } from "../../api/hooks";
import { getApiUrl } from "../../api/config";
import { useNavigate } from "react-router-dom";
import { useSubscription } from "../../shared/contexts/SubscriptionContext";
import FailureAlert from "../../shared/components/Alert/FailureAlert";

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  subscriptionID: number;
}

const DeleteSubscriptionModal: React.FC<Props> = ({ isOpen, setOpen, subscriptionID }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getSubscriptions } = useSubscription();
  const {
    deleteRequestStatus: deleteSubscriptionStatus,
    deleteRequest: deleteSubscriptionRequest,
  } = useDeleteRequest(`${getApiUrl()}/subscription/${subscriptionID}`);

  useEffect(() => {
    if (deleteSubscriptionStatus.status === "success") {
      navigate("/");
      getSubscriptions();
    }
  }, [deleteSubscriptionStatus, navigate]);

  const getModalBody = () => {
    switch (deleteSubscriptionStatus.status) {
      case "loading":
      case "notAsked":
      case "success":
        return (
          <ModalBody textAlign="center" mt={2}>
            <Text variant="sm" alignItems="center">
              {t("actions.sure?")}
            </Text>
            <Text variant="sm" mt={2}>
              {t("subscriptionPreference.pauseOption")}
            </Text>
          </ModalBody>
        );
      case "failure":
        return (
          <ModalBody textAlign="center" mt={2}>
            <FailureAlert
              variant="error"
              errorMessage={t("errorMessages.errorDeletingSubscription")}
            />
          </ModalBody>
        );
    }
  };
  const getModalFooter = () => {
    switch (deleteSubscriptionStatus.status) {
      case "loading":
      case "notAsked":
      case "success":
        return (
          <ModalFooter justifyContent="center" gap={2} overflowY="hidden">
            <Button
              variant="secondary"
              size="md"
              width="fit-content"
              onClick={() => deleteSubscriptionRequest()}
              isLoading={deleteSubscriptionStatus.status === "loading"}
            >
              {t("actions.deleteSubscription")}
            </Button>
            <Button variant="tertiary" size="md" width="fit-content" onClick={() => setOpen(false)}>
              {t("actions.cancel")}
            </Button>
          </ModalFooter>
        );
      case "failure":
        return (
          <ModalFooter justifyContent="center" gap={2}>
            <Button
              variant="secondary"
              size="md"
              width="fit-content"
              onClick={() => setOpen(false)}
            >
              {t("actions.ok")}
            </Button>
          </ModalFooter>
        );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setOpen(false)} size="full">
      <ModalOverlay />
      <ModalContent borderRadius={0}>
        <Stack
          backgroundColor={tokens.color.alias.seaMist}
          alignItems="center"
          justifyContent="space-around"
          paddingTop="3rem"
        >
          <img
            src={trashCan}
            alt="Illustration of a trash can"
            style={{ maxWidth: "80%", height: "45vh" }}
          />
        </Stack>
        <Box
          backgroundColor={tokens.color.alias.seaMist}
          clipPath={"ellipse(60% 2rem at 50% 30%)"}
          height="4rem"
          transform={"translateY(-2rem)"}
        ></Box>
        <ModalHeader alignSelf="center" padding={0}>
          <Text variant="lg" fontWeight="bold">
            {t("actions.deleteSubscription")}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        {getModalBody()}
        {getModalFooter()}
      </ModalContent>
    </Modal>
  );
};

export default DeleteSubscriptionModal;
