import React from "react";
import { AxiosError } from "axios";

interface NotAsked {
  status: "notAsked";
}

interface Loading {
  status: "loading";
}

interface Success<ResponseType> {
  status: "success";
  data: ResponseType;
}

interface Failure {
  status: "failure";
  error: AxiosError;
}

export const notAsked = (): NotAsked => ({
  status: "notAsked",
});

export const loading = (): Loading => ({
  status: "loading",
});

export const failure = <ResponseDataType,>(error: AxiosError): Failure => ({
  status: "failure",
  error,
});

export const success = <ResponseDataType,>(data: ResponseDataType): Success<ResponseDataType> => ({
  status: "success",
  data,
});

export type RequestStatus<ResponseDataType> =
  | NotAsked
  | Loading
  | Success<ResponseDataType>
  | Failure;

export const httpRequest = <ResponseType,>(
  request: Promise<ResponseType>,
  method: "GET" | "PUT" | "POST" | "PATCH" | "DELETE",
  url: string,
  state: RequestStatus<ResponseType>,
  setState: React.Dispatch<React.SetStateAction<RequestStatus<ResponseType>>>
) => {
  setState(loading());

  request
    .then(response => setState(success(response)))
    .catch(error => {
      setState(failure(error));
    });
};
