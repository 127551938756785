import React, { createContext, useContext, useEffect, useState } from "react";
import {
  AuthError,
  confirmSignIn as authConfirmSignIn,
  fetchAuthSession,
  signIn as authSignIn,
  signInWithRedirect as authSignInWithRedirect,
  signOut as authSignOut,
  confirmResetPassword as authConfirmResetPassword,
} from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";

import { getLoginData } from "../../auth/getLoginData";
import { Amplify } from "aws-amplify";
import { AuthNextSignInStep } from "@aws-amplify/auth/dist/esm/types/models";
import { getCurrentLanguage } from "../../environment";
import { Language } from "@vygruppen/spor-react";

let isConfigured = false;

interface AuthContextType {
  isAuthenticated: boolean;
  signIn: (username: string, password: string) => Promise<AuthResult>;
  confirmSignIn: (newPassword: string) => Promise<AuthResult>;
  signInWithRedirect: () => Promise<AuthResult>;
  signOut: () => Promise<AuthResult>;
  confirmResetPassword: (
    username: string,
    newPassword: string,
    confirmationCode: string
  ) => Promise<AuthResult>;
  groups: string[];
}

export interface AuthResult {
  success: boolean;
  message:
    | "SignInFailed"
    | "SignInFailedWrongPassword"
    | "SignInFailedExceededAttempts"
    | "SetNewPasswordFailed"
    | "ResetPasswordFailed"
    | "ResetPasswordWrongCode"
    | "MicrosoftSignInFailed"
    | "LoggedIn"
    | ""
    | "SignOutFailed"
    | "SignedOut"
    | AuthNextSignInStep["signInStep"];
}

type Props = {
  children?: React.ReactNode;
};

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const configureAmplify = (userPoolId: string, userPoolClientId: string, domain: string) => {
  if (isConfigured) {
    return;
  }
  isConfigured = true;
  Amplify.configure({
    Auth: {
      Cognito: {
        loginWith: {
          oauth: {
            redirectSignIn: [`${window.location.origin}/login-redirect`],
            redirectSignOut: [`${window.location.origin}`],
            // Domain is set in terraform with https:// at the beginning, must be removed here to avoid double https in the URL
            domain: domain.replace("https://", ""),
            scopes: ["openid"],
            responseType: "code",
          },
        },
        userPoolId: userPoolId,
        userPoolClientId: userPoolClientId,
      },
    },
  });
};

const useProvideAuth = (): AuthContextType => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [groups, setGroups] = useState<string[]>([]);
  const [isAuthConfigured, setIsAuthConfigured] = useState(false);

  /* We call fetchSettings first as we need to configure Amplify with settings from the server */
  useEffect(() => {
    const fetchSettings = async () => {
      const { notificationUserPoolId, notificationClientId, notificationAuthUrl } =
        await getLoginData();
      configureAmplify(notificationUserPoolId, notificationClientId, notificationAuthUrl);
    };

    fetchSettings()
      .then(() => setIsAuthConfigured(true))
      .catch(_ => {
        const errorMessage =
          getCurrentLanguage() === Language.NorwegianBokmal
            ? "Noe gikk alvorlig galt, prøv igjen senere."
            : "Något gick allvarligt fel, försök igen senare.";
        alert(errorMessage);
      });
  }, []);

  /* If we have configured amplify auth, fetch user groups from session */
  useEffect(() => {
    if (isAuthConfigured) {
      /* Amplify Auth listener, signedIn is emitted for both redirected and normal signin.  */
      const unsubscribe = Hub.listen("auth", ({ payload }) => {
        switch (payload.event) {
          case "signedIn":
            // noinspection JSIgnoredPromiseFromCall
            getUserGroups();
            break;
          case "signedOut":
          case "signInWithRedirect_failure":
          case "tokenRefresh_failure":
            setIsAuthenticated(false);
            setGroups([]);
            break;
        }
      });

      // noinspection JSIgnoredPromiseFromCall
      getUserGroups();
      return unsubscribe;
    }
  }, [isAuthConfigured]);

  const getUserGroups = async () => {
    try {
      const authSession = await fetchAuthSession();
      if (authSession && authSession.tokens?.accessToken) {
        setIsAuthenticated(true);
        const groups: string[] = authSession.tokens?.accessToken.payload[
          "cognito:groups"
        ] as string[];
        if (groups && groups.filter(g => g.includes("ADMIN")).length !== 0) {
          setGroups(groups);
        }
      }
    } catch (e) {
      setIsAuthenticated(false);
      setGroups([]);
    }
  };

  const signIn = async (username: string, password: string): Promise<AuthResult> => {
    try {
      const { isSignedIn, nextStep } = await authSignIn({
        username,
        password,
      });
      return { success: isSignedIn, message: nextStep.signInStep };
    } catch (error) {
      if (error instanceof AuthError) {
        if (error.message === "Incorrect username or password.") {
          return {
            success: false,
            message: "SignInFailedWrongPassword",
          };
        }
        if (error.message === "Password reset required for the user") {
          return {
            success: false,
            message: "CONFIRM_SIGN_IN_WITH_SMS_CODE",
          };
        }
        if (error.message === "Password attempts exceeded") {
          return {
            success: false,
            message: "SignInFailedExceededAttempts",
          };
        }
      }
      return {
        success: false,
        message: "SignInFailed",
      };
    }
  };

  const signOut = async (): Promise<AuthResult> => {
    try {
      await authSignOut();
      return {
        success: true,
        message: "SignedOut",
      };
    } catch (error) {
      return {
        success: false,
        message: "SignOutFailed",
      };
    }
  };

  const signInWithRedirect = async (): Promise<AuthResult> => {
    try {
      await authSignInWithRedirect({
        provider: { custom: "VyGruppen" },
      });

      return { success: true, message: "LoggedIn" };
    } catch (error) {
      return {
        success: false,
        message: "MicrosoftSignInFailed",
      };
    }
  };

  const confirmSignIn = async (newPassword: string): Promise<AuthResult> => {
    try {
      const { isSignedIn, nextStep } = await authConfirmSignIn({
        challengeResponse: newPassword,
      });

      return { success: isSignedIn, message: nextStep.signInStep };
    } catch (error) {
      return {
        success: false,
        message: "SetNewPasswordFailed",
      };
    }
  };

  const confirmResetPassword = async (
    username: string,
    newPassword: string,
    confirmationCode: string
  ): Promise<AuthResult> => {
    try {
      await authConfirmResetPassword({
        username,
        confirmationCode,
        newPassword,
      });
      return { success: true, message: "LoggedIn" };
    } catch (error) {
      if (error instanceof AuthError) {
        if (error.message === "Invalid verification code provided, please try again") {
          return {
            success: false,
            message: "ResetPasswordWrongCode",
          };
        }
      }
      return {
        success: false,
        message: "ResetPasswordFailed",
      };
    }
  };
  return {
    isAuthenticated,
    signIn,
    confirmSignIn,
    signInWithRedirect,
    signOut,
    confirmResetPassword,
    groups,
  };
};
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("Du er utenfor Auth-konteksten!");
  }
  return context;
};
