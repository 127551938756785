import { useEffect, useState } from "react";
import { useGetRequest } from "../../api/hooks";
import { getApiUrl } from "../../api/config";
import { Line } from "../Types";
import { getCurrentCountryCode } from "../../environment";

const useLines = () => {
  const { getRequestStatus: getLineStatus, getRequest: getLineRequest } = useGetRequest<Line[]>(
    `${getApiUrl()}/line-ids/${getCurrentCountryCode()}`
  );
  const [lines, setLines] = useState<Line[]>([]);

  useEffect(() => {
    if (getLineStatus.status === "notAsked") getLineRequest();
    if (getLineStatus.status === "failure") setLines([]);
    if (getLineStatus.status === "success") {
      const data = getLineStatus.data || [];
      setLines(data);
    }
  }, [getLineStatus.status]);

  return { lines, getLineStatus };
};

export default useLines;
