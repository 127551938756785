import React, { useEffect } from "react";
import { useSubscriptionData } from "../../shared/hooks/useSubscriptionData";
import { NewSubscription as NewSubscriptionType, NotificationChannel } from "../../shared/Types";
import { useSubscription } from "../../shared/contexts/SubscriptionContext";
import ErrorModal from "../../shared/components/Modal/ErrorModal";
import { useDisclosure } from "@vygruppen/spor-react";
import { useNavigate } from "react-router-dom";
import { notAsked } from "../../api/httpRequest";
import { useTranslation } from "react-i18next";
import { useUser } from "../../shared/contexts/UserContext";

export const NewSubscription = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { saveSubscription, saveSubscriptionStatus, setSaveSubscriptionStatus, getSubscriptions } =
    useSubscription();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { user, isAdmin } = useUser();

  useEffect(() => {
    if (saveSubscriptionStatus.status === "success") {
      getSubscriptions();
      setSaveSubscriptionStatus(notAsked());
      navigate("/");
    }
  }, [saveSubscriptionStatus.status]);

  const handleSave = () => {
    let notificationChannel: NotificationChannel;
    if (channelChoice.includes("SMS") && channelChoice.includes("EMAIL")) {
      notificationChannel = "EMAIL_AND_SMS";
    } else if (channelChoice.includes("SMS")) {
      notificationChannel = "SMS";
    } else {
      notificationChannel = "EMAIL";
    }
    const { pickAll, ...days } = day;
    const recipients = isAdmin ? selectedRecipients : [{ email: user!!.email, id: user!!.id }];
    const payload: NewSubscriptionType = {
      name: title,
      isEnabled: true,
      eventFilters: checkForAllEvent(events),
      lineFilters: checkForAllLine(lineFilters),
      stationFilters: stationFilters,
      notificationChannel: notificationChannel,
      notificationInterval: {
        start: timeFrom.toString(),
        end: timeTo.toString(),
        daysOfWeek: days,
      },
      recipients: recipients,
    };
    saveSubscription(payload);
  };

  const {
    events,
    lineFilters,
    stationFilters,
    channelChoice,
    timeFrom,
    timeTo,
    title,
    day,
    checkForAllEvent,
    checkForAllLine,
    stepper,
    content,
    selectedRecipients,
  } = useSubscriptionData({ handleSave, show: "new" });

  return (
    <>
      {stepper}
      {content}
      {saveSubscriptionStatus.status === "failure" && (
        <ErrorModal
          isOpen={isOpen}
          onClose={onClose}
          errorMessage={t("errorMessages.failedToSaveSubscription") ?? undefined}
        />
      )}
    </>
  );
};
