import { useTranslation } from "react-i18next";
import React, { FC, useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  PasswordInput,
} from "@vygruppen/spor-react";
import FailureAlert from "../../shared/components/Alert/FailureAlert";

interface Props {
  handleNewPassword: (newPassword: string) => void;
  isLoading: boolean;
  errorMessage: string | undefined;
  disableSubmitButton?: boolean;
}

export const NewPasswordForm: FC<Props> = ({
  handleNewPassword,
  isLoading,
  errorMessage,
  disableSubmitButton,
}) => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  // At least one small letter, one big, one number, one special character, min-length: 12
  // Special characters: https://owasp.org/www-community/password-special-characters
  // prettier-ignore
  const isInvalidPassword = (pw: string): boolean => {
    return (
      !RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!#$%&'()*+,-./:;<=>?@\\[\\]^_`{|}~ .])[A-Za-z0-9!#$%&'()*+,-. /:;<=>?@\\[\\]^_`{|}~]{12,}$").test(pw)
    );
  };

  const isInvalidInput = () => {
    return isInvalidPassword(newPassword) || newPassword !== confirmPassword;
  };

  const isButtonDisabled = () => {
    if (disableSubmitButton) return true;
    return isInvalidInput();
  };

  return (
    <>
      {errorMessage && <FailureAlert variant="error" errorMessage={errorMessage} />}
      <FormControl
        mb={1.5}
        mt={errorMessage ? 2 : 0}
        isInvalid={newPassword.length !== 0 && isInvalidPassword(newPassword)}
      >
        <FormHelperText color="black">{t("setNewPassword")}</FormHelperText>
        <PasswordInput
          label={t("newPasswordInputLabel")}
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
        <FormErrorMessage>{t("invalidPassword")}</FormErrorMessage>
      </FormControl>
      <FormControl
        mb={2}
        isInvalid={confirmPassword.length !== 0 && confirmPassword !== newPassword}
      >
        <PasswordInput
          label={t("confirmPasswordInputLabel")}
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
        />
        <FormErrorMessage>{t("notMatchingPasswords")}</FormErrorMessage>
      </FormControl>
      <Button
        isDisabled={isButtonDisabled()}
        variant="primary"
        size="lg"
        width="100%"
        onClick={() => handleNewPassword(confirmPassword)}
        isLoading={isLoading}
      >
        {t("setNewPassword")}
      </Button>
    </>
  );
};
