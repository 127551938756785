import React, { ErrorInfo } from "react";
import { Button, Heading, Text } from "@vygruppen/spor-react";
import styled from "@emotion/styled";

type ErrorState = {
  errorInfo: ErrorInfo | null;
};

export class ErrorBoundary extends React.Component<any, ErrorState> {
  constructor(props: any) {
    super(props);
    this.state = { errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ errorInfo });
  }

  render() {
    const { props, state } = this;
    if (state.errorInfo) {
      return (
        <Container style={{ padding: "1rem" }}>
          <Heading as="h2">En feil har oppstått</Heading>
          <Text variant="sm" pt={2}>
            Dersom feilen er gjentakende, send gjerne en beskrivelse av hvordan du gikk fram da
            feilen oppstod til Team-Traffic-Control@vy.no
          </Text>
          <a href="/">
            <Button variant="primary" size="sm" margin={3}>
              Gå til forsiden
            </Button>
          </a>
        </Container>
      );
    }
    return props.children;
  }
}

const Container = styled("div")`
  text-align: center;
  margin: 10% auto;
  max-width: 80%;
`;
