import { fetchAuthSession } from "aws-amplify/auth";
import axios, { AxiosRequestConfig } from "axios";
import { Environment, getCurrentEnvironment } from "environment";
import appConfig from "appConfig.json";

const getAccessToken = async () => {
  try {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    if (accessToken !== undefined) return accessToken;
  } catch (_) {} // Ignore exception and throw new error
  throw new Error("Failed to get access token");
};

const buildAuthHeader = async (): Promise<string> => {
  const token = await getAccessToken();
  return `Bearer ${token.toString()}`;
};

(axios.defaults.headers as any).common["Content-Type"] = "application/json;charset=utf-8";

export const requestConfigWithAuth = async (): Promise<AxiosRequestConfig> => {
  const authHeader = await buildAuthHeader();
  return {
    headers: {
      Authorization: authHeader,
    },
  };
};

export const getBaseUrl = () => {
  const env = getCurrentEnvironment();

  switch (env) {
    case Environment.DEVELOPMENT:
    case Environment.DEVELOPMENT_SE:
      return appConfig.backend.baseUrl.DEV;

    case Environment.TEST:
    case Environment.TEST_SE:
      return appConfig.backend.baseUrl.TEST;

    case Environment.STAGE:
    case Environment.STAGE_SE:
      return appConfig.backend.baseUrl.STAGE;

    case Environment.PRODUCTION:
    case Environment.PRODUCTION_SE:
      return appConfig.backend.baseUrl.PROD;

    default:
      return appConfig.backend.baseUrl.TEST;
  }
};

export const getApiUrl = () => {
  const env = getCurrentEnvironment();
  const contextPath = appConfig.backend.contextPath;

  switch (env) {
    case Environment.DEVELOPMENT:
    case Environment.DEVELOPMENT_SE:
      return `${getBaseUrl()}`;

    default:
      return `${getBaseUrl()}${contextPath}`;
  }
};
