import React, { useEffect } from "react";
import { Loading } from "shared/components/Loading";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { FeatureFlagProvider } from "./features/FeatureFlags/FeatureFlagContext";
import { Heading, Language, StaticAlert } from "@vygruppen/spor-react";
import { useGetRequest, usePostRequest } from "./api/hooks";
import { getApiUrl } from "./api/config";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "./shared/components/ErrorBoundary/ErrorBoundary";
import SubscriptionList from "./features/Subscriptions/SubscriptionList";
import { User } from "./shared/Types";
import { RequestStatus, success } from "./api/httpRequest";
import { NewSubscription } from "./features/NewSubscription/NewSubscription";
import { useUser } from "./shared/contexts/UserContext";
import { EditSubscription } from "./features/SubscriptionEdit/EditSubscription";
import SubscriptionInformation from "./features/SubscriptionInformation/SubscriptionInformation";
import { getCurrentLanguage } from "./environment";
import { SubscriptionProvider } from "./shared/contexts/SubscriptionContext";
import FailureAlert from "./shared/components/Alert/FailureAlert";
import { useAuth } from "./shared/contexts/AuthContext";
import { StationsProvider } from "./shared/contexts/StationsContext";

export const AuthenticatedApp: React.FC = () => {
  const {
    getRequestStatus: getUser,
    getRequest,
    setGetRequestStatus,
  } = useGetRequest<User>(`${getApiUrl()}/user`);
  const { postRequestStatus: postUser, postRequest } = usePostRequest<User, any>(
    `${getApiUrl()}/user`
  );
  const { groups } = useAuth();
  const { setUser, setIsAdmin } = useUser();

  useEffect(() => {
    if (getUser.status === "notAsked") getRequest();
    // User was not found, send POST request to create user
    if (getUser.status === "failure")
      postRequest(getCurrentLanguage() === Language.NorwegianBokmal ? "NORWEGIAN" : "SWEDISH");
    if (getUser.status === "success") {
      setUser(getUser.data);
      setIsAdmin(groups.length > 0);
    }
  }, [getUser.status]);

  useEffect(() => {
    if (postUser.status === "success") {
      // Set getUser to success -> will trigger change of getUser.status -> useEffect over will set the user
      setGetRequestStatus(success(postUser.data));
    }
  }, [postUser.status]);

  const getContent = () => {
    switch (getUser.status) {
      case "success":
        return (
          <ErrorBoundary>
            <Router>
              <FeatureFlagProvider>
                <SubscriptionProvider>
                  <StationsProvider>
                    <Routes>
                      <Route path="/" element={<SubscriptionList />} />
                      <Route
                        path="/subscription/:subscriptionId"
                        element={<SubscriptionInformation />}
                      />
                      <Route
                        path="/subscription/edit/:subscriptionId"
                        element={<EditSubscription />}
                      />
                      <Route path="*" element={<Navigate to="/" />} />
                      <Route path="/subscription/new" element={<NewSubscription />} />
                    </Routes>
                  </StationsProvider>
                </SubscriptionProvider>
              </FeatureFlagProvider>
            </Router>
          </ErrorBoundary>
        );
      case "notAsked":
      case "loading":
        return <VerifyUser />;
      case "failure":
        return <NewUser postUser={postUser} />;
    }
  };

  return getContent();
};

const VerifyUser: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="App-container">
      <p style={{ margin: 0, fontSize: "1rem" }}>{t("loadingUser")}</p>
      <Loading width="20rem" />
    </div>
  );
};

const NewUser: React.FC<{ postUser: RequestStatus<User> }> = ({ postUser }) => {
  const { t } = useTranslation();

  const getContent = () => {
    switch (postUser.status) {
      case "success":
        return (
          <StaticAlert variant="success" margin={2}>
            {t("welcomeUser")}
          </StaticAlert>
        );
      case "notAsked":
      case "loading":
        return (
          <div className="App-container">
            <p style={{ margin: 0, fontSize: "1rem" }}>{t("addingUser")}</p>
            <Loading width="20rem" />
          </div>
        );
      case "failure":
        return <FailureAlert variant="error" errorMessage={t("errorAddingUser")} />;
    }
  };
  return (
    <div className="App-container">
      <Heading as="h2" variant="xl-sans">
        Hei!
      </Heading>
      {getContent()}
    </div>
  );
};
