import React, { Dispatch, Key, SetStateAction, useMemo, useState } from "react";
import { StationFilter } from "../../shared/Types";
import { useTranslation } from "react-i18next";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ChoiceChip,
  Flex,
  FormControl,
  SearchInput,
  Text,
} from "@vygruppen/spor-react";
import { CloseOutline18Icon } from "@vygruppen/spor-icon-react";
import tokens from "@vygruppen/spor-design-tokens";
import { useStations } from "../../shared/contexts/StationsContext";
import { FixedSizeList } from "react-window";

interface Props {
  stationFilters: StationFilter[];
  setStationFilters: Dispatch<SetStateAction<StationFilter[]>>;
}

const StationSelectAccordion: React.FC<Props> = ({ stationFilters, setStationFilters }) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const { stations } = useStations();

  // Use blur and focus to hide or show the dropdown with stations
  let blurTimeout: NodeJS.Timeout;

  const hideDropdown = () => {
    blurTimeout = setTimeout(() => {
      setIsSearching(false);
    }, 100);
  };

  const showDropdown = () => {
    clearTimeout(blurTimeout);
    setIsSearching(true);
  };

  const handleSelectionChange = (jbvId: string) => {
    const selected = stationFilters.some(stationFilter => stationFilter.jbvId === jbvId);
    if (selected) {
      removeStation(jbvId);
    } else {
      setStationFilters(prev => [...prev, { jbvId: jbvId }]);
    }
    setQuery("");
  };

  const removeStation = (jbvId: string) => {
    setStationFilters(prev => prev.filter(it => it.jbvId !== jbvId));
  };

  const filteredStations = useMemo((): [string, string][] => {
    if (query.length >= 2) {
      return Array.from(stations).filter(([_, name]) =>
        name.toLowerCase().includes(query.toLowerCase())
      );
    } else {
      return Array.from(stations);
    }
  }, [query, stations]);

  const ITEM_HEIGHT = 36;
  const MAX_SCROLL_HEIGHT = 300;

  const renderStationList = () => {
    const height =
      filteredStations.length * ITEM_HEIGHT < MAX_SCROLL_HEIGHT
        ? filteredStations.length * ITEM_HEIGHT + 12
        : MAX_SCROLL_HEIGHT;

    return (
      isSearching &&
      query.length > 0 &&
      filteredStations.length > 0 && (
        <Box
          className="station-list"
          borderRadius="sm"
          borderTopRadius={query.length > 0 ? 0 : "sm"}
          border="1px solid rgba(0,0,0,0.4)"
          onBlur={hideDropdown}
          onFocus={showDropdown}
        >
          <FixedSizeList
            height={height}
            itemCount={filteredStations.length}
            itemData={filteredStations}
            itemSize={ITEM_HEIGHT}
            width="100%"
            className={"station-list"}
          >
            {({ data, index, style }) => {
              const [jbvId, name] = data[index];
              const selected = stationFilters.some(it => it.jbvId === jbvId);
              const selectedText = selected ? `(${t("stationSelect.selected")})` : "";
              return (
                <Button
                  key={jbvId}
                  style={{
                    ...style,
                    width: "calc(100% - 12px)",
                    margin: "6px",
                    borderRadius: tokens.size["border-radius"].sm,
                  }}
                  variant="ghost"
                  size="sm"
                  rightIcon={selected ? <CloseOutline18Icon /> : <></>}
                  onClick={() => handleSelectionChange(jbvId)}
                >
                  <Text variant="sm" color={selected ? tokens.color.alias.dimGrey : "initial"}>
                    {name} {selectedText}
                  </Text>
                </Button>
              );
            }}
          </FixedSizeList>
        </Box>
      )
    );
  };

  return (
    <AccordionItem>
      <AccordionButton fontWeight={"bold"}>
        {t("stationFilter")} ({stationFilters.length})
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel
        sx={{
          '[role = "listbox"]': {
            maxHeight: "min(300px, 30vh)",
          },
        }}
      >
        <Text variant="sm" pb={2}>
          {t("stationSelect.filterExplanation")}
        </Text>

        <FormControl className="station-search">
          <SearchInput
            label={t("stationSelect.selectStation")}
            value={query}
            onChange={e => setQuery(e.target.value)}
            onBlur={hideDropdown}
            onFocus={showDropdown}
            borderBottomRadius={query.length > 0 ? "0" : "sm"}
          />
        </FormControl>
        {renderStationList()}

        <Flex gap={1} wrap="wrap" my={2}>
          {stationFilters.length === 0 && (
            <Text variant="sm">{t("stationSelect.noneSelected")}</Text>
          )}
          {stationFilters.map(({ jbvId }) => (
            <ChoiceChip
              key={jbvId}
              chipType="filter"
              size="xs"
              isChecked
              onChange={() => removeStation(jbvId)}
            >
              {stations.get(jbvId) ?? jbvId}
            </ChoiceChip>
          ))}
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default StationSelectAccordion;
