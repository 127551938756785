import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ContentLoader,
  Flex,
  Heading,
  PressableCard,
  Stack,
  Switch,
  Text,
} from "@vygruppen/spor-react";
import { AddFill18Icon } from "@vygruppen/spor-icon-react";
import { useTranslation } from "react-i18next";
import { MainContainer } from "../../shared/components/Container/MainContainer";
import { useNavigate } from "react-router-dom";
import formatSubscription from "../../shared/hooks/useFormatSubscriptionData";
import NoSubscriptions from "./NoSubscriptions";
import { useSubscription } from "../../shared/contexts/SubscriptionContext";
import DelayedLoading from "../../shared/components/Loading/DelayedLoading";
import ErrorModal from "../../shared/components/Modal/ErrorModal";
import FailureAlert from "../../shared/components/Alert/FailureAlert";
import UserInfo from "./UserInfo/UserInfo";

const SubscriptionList = () => {
  const { formatSubscriptionInterval } = formatSubscription();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { subscriptions, getSubscriptionsStatus, updateSubscription, updateSubscriptionStatus } =
    useSubscription();

  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const [lastFlippedIndex, setLastFlippedIndex] = useState(0);

  useEffect(() => {
    if (updateSubscriptionStatus.status === "failure") {
      setErrorModalOpen(true);
    }
  }, [updateSubscriptionStatus.status]);
  const handleSwitchToggle = (index: number) => {
    const subscription = toggleSubscription(index);
    setLastFlippedIndex(index);
    updateSubscription(subscription);
  };

  const toggleSubscription = (index: number) => {
    const subscription = subscriptions[index];
    subscription.isEnabled = !subscription.isEnabled;
    return subscription;
  };

  const getContent = () => {
    switch (getSubscriptionsStatus.status) {
      case "success":
        return (
          <>
            {subscriptions.length === 0 ? (
              <NoSubscriptions />
            ) : (
              <Stack>
                <Heading as="h2" variant="sm" fontWeight="bold" mt={3}>
                  {t("recurringSubscription")}
                </Heading>
                {subscriptions
                  .sort((subA, subB) => subA.id - subB.id)
                  .map((subscription, index) => (
                    <PressableCard
                      key={subscription.id}
                      variant="floating"
                      backgroundColor={subscription.isEnabled ? "white" : "lightGrey"}
                      padding="1rem"
                      onClick={() => navigate(`/subscription/${subscription.id}`)}
                      as="button"
                    >
                      <Flex justifyContent="space-between" alignItems="center">
                        <Box
                          textAlign="start"
                          sx={subscription.isEnabled ? {} : { color: "dimGrey" }}
                        >
                          <Text variant="sm">
                            {subscription.isEnabled
                              ? subscription.name
                              : `${subscription.name} (Pause)`}
                          </Text>
                          <Text variant="xs">{formatSubscriptionInterval(subscription)}</Text>
                        </Box>
                        <Box onClick={event => event.stopPropagation()}>
                          <Switch
                            isChecked={subscription.isEnabled}
                            onChange={() => handleSwitchToggle(index)}
                          />
                        </Box>
                      </Flex>
                    </PressableCard>
                  ))}
                <Button
                  variant="secondary"
                  size="sm"
                  fontWeight="bold"
                  leftIcon={<AddFill18Icon />}
                  mt={1}
                  mb={2}
                  onClick={() => navigate("/subscription/new")}
                >
                  {t("subscriptionList.addSubscription")}
                </Button>
              </Stack>
            )}
            <UserInfo />
          </>
        );
      case "loading":
      case "notAsked":
        return (
          <DelayedLoading delay={1000}>
            <ContentLoader padding={4}>{t("subscriptionList.loading")}</ContentLoader>
          </DelayedLoading>
        );
      case "failure":
        return (
          <FailureAlert
            variant="error"
            errorMessage={t("errorMessages.errorFetchingSubscriptions")}
          />
        );
    }
  };

  return (
    <MainContainer>
      <Stack justifyContent="space-between" width="100%">
        {getContent()}
      </Stack>
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => {
          toggleSubscription(lastFlippedIndex);
          setErrorModalOpen(false);
        }}
        errorMessage={t("errorMessages.errorUpdatingSubscription") ?? undefined}
      />
    </MainContainer>
  );
};
export default SubscriptionList;
