import { Language } from "@vygruppen/spor-react";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import { nb } from "./translations/nb";
import { sv } from "./translations/sv";
import { getCurrentLanguage } from "./environment";

export const supportedLanguages = [Language.NorwegianBokmal, Language.English];

i18n.use(initReactI18next).init({
  resources: {
    nb: { translation: nb },
    sv: { translation: sv },
  },
  fallbackLng: "nb",
  lng: getCurrentLanguage() === Language.NorwegianBokmal ? "nb" : "sv",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
