import React, { useState } from "react";
import { Box, Button, Flex, Stack, Stepper, Time } from "@vygruppen/spor-react";
import {
  DaysOfWeekWithPickAll,
  EventFilter,
  LineFilter,
  Recipient,
  StationFilter,
} from "shared/Types";
import { useTranslation } from "react-i18next";
import { Step1Content } from "../../features/NewSubscription/Step1Content";
import { Step2Content } from "../../features/NewSubscription/Step2Content";
import { Step3Content } from "../../features/NewSubscription/Step3Content";
import { ArrowLeftOutline24Icon, ArrowRightOutline24Icon } from "@vygruppen/spor-icon-react";
import { ContentContainer } from "../components/Container/ContentContainer";
import { useNavigate } from "react-router-dom";
import { useSubscription } from "../contexts/SubscriptionContext";
import { useUser } from "../contexts/UserContext";

interface Props {
  handleSave: () => void;
  show: string;
}

export const useSubscriptionData = ({ handleSave, show }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { saveSubscriptionStatus, updateSubscriptionStatus } = useSubscription();
  const { isAdmin } = useUser();
  const [step, setStep] = useState<number>(1);
  const [events, setEvents] = useState<EventFilter[]>([]);
  const [lineFilters, setLineFilters] = useState<LineFilter[]>([]);
  const [stationFilters, setStationFilters] = useState<StationFilter[]>([]);
  const [isInvalidTime, setIsInvalidTime] = useState(false);
  const [channelChoice, setChannelChoice] = useState(["EMAIL"]);
  const [timeFrom, setTimeFrom] = useState(new Time(6, 0));
  const [timeTo, setTimeTo] = useState(new Time(22, 0));
  const [title, setTitle] = useState<string>("");
  const [selectedRecipients, setSelectedRecipients] = useState<Recipient[]>([]);

  const daysOfWeek: DaysOfWeekWithPickAll = {
    pickAll: false,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
    sunday: false,
  };

  const [day, setDay] = useState<DaysOfWeekWithPickAll>(daysOfWeek);

  function renderStepContent() {
    switch (step) {
      case 1:
        return <Step1Content events={events} setEvents={setEvents} />; //
      case 2:
        return (
          <Step2Content
            lineFilters={lineFilters}
            setLineFilters={setLineFilters}
            stationFilters={stationFilters}
            setStationFilters={setStationFilters}
          />
        );
      case 3:
        return (
          <Step3Content
            day={day}
            setDay={setDay}
            timeFrom={timeFrom}
            setTimeFrom={setTimeFrom}
            timeTo={timeTo}
            setTimeTo={setTimeTo}
            daysOfWeek={daysOfWeek}
            isInvalidTime={isInvalidTime}
            setIsInvalidTime={setIsInvalidTime}
            channelChoice={channelChoice}
            setChannelChoice={setChannelChoice}
            title={title}
            setTitle={setTitle}
            selectedRecipients={selectedRecipients}
            setSelectedRecipients={setSelectedRecipients}
          />
        );
      default:
        return null;
    }
  }

  function onPressNextButton() {
    setStep(step + 1);
  }

  function onPressPrevButton() {
    setStep(step - 1);
  }

  function nextButton(len: number) {
    return (
      <Button
        variant="secondary"
        size="md"
        ml="auto"
        onClick={() => onPressNextButton()}
        isDisabled={len === 0}
        rightIcon={<ArrowRightOutline24Icon />}
      >
        {t("actions.next")}
      </Button>
    );
  }

  function saveButton() {
    return (
      <Button
        id={"saveButton"}
        variant="primary"
        size={"md"}
        ml={"auto"}
        isDisabled={
          Object.values(day).every(item => !item) ||
          isInvalidTime ||
          channelChoice.length === 0 ||
          title === "" ||
          (isAdmin && selectedRecipients.length === 0)
        }
        onClick={() => handleSave()}
        isLoading={
          saveSubscriptionStatus.status === "loading" ||
          updateSubscriptionStatus.status === "loading"
        }
      >
        <span style={{ marginRight: "18px", marginLeft: "18px" }}>{t("actions.save")}</span>{" "}
      </Button>
    );
  }

  function renderNextButton() {
    switch (step) {
      case 1:
        return nextButton(events.length);
      case 2:
        return nextButton(lineFilters.length || stationFilters.length);
      case 3:
        return saveButton();
      default:
        return null;
    }
  }

  let backButton = (
    <Button
      variant="secondary"
      size="md"
      onClick={() => onPressPrevButton()}
      leftIcon={<ArrowLeftOutline24Icon />}
    >
      {t("actions.back")}
    </Button>
  );

  let outButton = (
    <Button
      variant="secondary"
      size="md"
      onClick={() => navigate(-1)}
      leftIcon={<ArrowLeftOutline24Icon />}
    >
      {t("actions.back")}
    </Button>
  );

  function renderBackButton() {
    switch (step) {
      case 1:
        return outButton;
      case 2:
        return backButton;
      case 3:
        return backButton;
      default:
        return null;
    }
  }

  const checkForAllEvent = (filters: EventFilter[]) => {
    const filterTypeMap: { [key: string]: boolean } = {}; // Map to track if "ALL" is present for each filterType

    filters.forEach(filter => {
      const { filterType, filterValue } = filter;
      if (filterValue === "ALL") {
        filterTypeMap[filterType] = true;
      }
    });

    const updatedFilters = filters.filter(filter => {
      const { filterType, filterValue } = filter;
      return !filterTypeMap[filterType] || filterValue === "ALL";
    });
    return updatedFilters;
  };

  const getStepperTitle = (show: string) => {
    return show.includes("edit")
      ? t("actions.editSubscription")
      : t("subscription.newSubscription");
  };

  const checkForAllLine = (filters: LineFilter[]) => {
    const all = filters.filter(filter => filter.filterValue.id === "ALL");
    const containsAll = all.length > 0;
    return containsAll ? all : filters;
  };

  const stepper = (
    <Box position={"fixed"} width={"100%"} height={"60px"} zIndex={1}>
      <Stepper
        onClick={newStep => {
          // Prevent user from skipping steps
          if (newStep <= step) setStep(newStep);
        }}
        variant="accent"
        activeStep={step}
        steps={["Steg 1", "Steg 2", "Steg 3"]}
        title={getStepperTitle(show)}
      />
    </Box>
  );

  const content = (
    <ContentContainer paddingTop={"60px"}>
      <Stack gap={"1rem"} justifyContent={"space-between"} minWidth={0} flex={1}>
        <Box>{renderStepContent()}</Box>
        <Flex marginTop={"auto"} py={5}>
          {renderBackButton()}
          {renderNextButton()}
        </Flex>
      </Stack>
    </ContentContainer>
  );

  return {
    events,
    setEvents,
    lineFilters,
    setLineFilters,
    stationFilters,
    setStationFilters,
    channelChoice,
    setChannelChoice,
    timeFrom,
    setTimeFrom,
    timeTo,
    setTimeTo,
    title,
    setTitle,
    day,
    setDay,
    handleSave,
    checkForAllEvent,
    checkForAllLine,
    stepper,
    content,
    selectedRecipients,
    setSelectedRecipients,
  };
};
