import React, { useState } from "react";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import {
  Box,
  Button,
  CloseButton,
  DarkMode,
  Flex,
  IconButton,
  LightMode,
  Text,
  Tooltip,
} from "@vygruppen/spor-react";
import { ProfileFill30Icon } from "@vygruppen/spor-icon-react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import FailureAlert from "../Alert/FailureAlert";

const Profile: React.FC = () => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleSignOutClick = async () => {
    if (errorMessage) setErrorMessage(undefined);
    const result = await signOut();
    if (result.success) {
      navigate(0);
    } else {
      setErrorMessage(t("logoutError"));
    }
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };

  const handlePopoverToggle = () => {
    setPopoverOpen(prevOpen => !prevOpen);
  };

  return (
    <Tooltip
      placement="bottom"
      arrowSize={12}
      arrowShadowColor="none"
      arrowPadding={10}
      offset={[-110, 9]}
      isOpen={isPopoverOpen}
      content={
        <>
          <DarkMode>
            <Flex flexDirection="row" justifyContent="space-between">
              <Text variant="sm" ml={1} my={2}>
                {t("logOut?")}
              </Text>
              <CloseButton onClick={handlePopoverClose} alignSelf="flex-start" ml={1} />
            </Flex>
            <Button
              variant="tertiary"
              size="sm"
              onClick={handleSignOutClick}
              mb={2}
              isDisabled={!isPopoverOpen}
            >
              {t("logOut")}
            </Button>
          </DarkMode>
          {errorMessage && <FailureAlert variant="error" errorMessage={errorMessage} />}
        </>
      }
    >
      <IconButton
        aria-label={t("profile")}
        icon={<ProfileFill30Icon />}
        variant="ghost"
        onClick={handlePopoverToggle}
        mr={2}
      />
    </Tooltip>
  );
};
export default Profile;
