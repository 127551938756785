import React, { useState } from "react";
import { StaticAlert, Text, TextLink } from "@vygruppen/spor-react";
import { useTranslation } from "react-i18next";
import RegisterPhoneNumberModal from "../../shared/components/Modal/RegisterPhoneNumberModal";

export const NoRegisteredPhoneNumberWarning = () => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <StaticAlert variant={"warning"}>
        <Text>
          {t("phoneNumber.couldNotFindNumber")} <br />
          <br />
          <TextLink variant="secondary" isExternal={true} onClick={openModal}>
            {t("phoneNumber.registerPhoneNumberHere")}
          </TextLink>
        </Text>
      </StaticAlert>
      <RegisterPhoneNumberModal isOpen={modalOpen} setOpen={setModalOpen} />
    </>
  );
};
