import React, { useEffect } from "react";
import { ErrorBoundary } from "shared/components/ErrorBoundary/ErrorBoundary";
import { BrowserRouter as Router, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Login } from "features/Login/Login";
import { Loading } from "shared/components/Loading";
import { useAuth } from "./shared/contexts/AuthContext";
import { ChangePassword } from "./features/Login/ChangePassword";
import { LoginExternal } from "./features/Login/ExternalLogin";
import { ResetPassword } from "./features/Login/ResetPassword";

const AuthenticateAndRedirect: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) navigate("/", { replace: true });
  }, [isAuthenticated]);

  return (
    <div className="App-container">
      <p style={{ margin: 0, fontSize: "1rem" }}>Logger deg inn...</p>
      <Loading width="20rem" />
    </div>
  );
};

export const UnauthenticatedApp: React.FC = () => {
  return (
    <ErrorBoundary>
      <Router>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/login-redirect" element={<AuthenticateAndRedirect />}></Route>
          <Route path="/external" element={<LoginExternal />}></Route>
          <Route path="/change-password" element={<ChangePassword />}></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </Router>
    </ErrorBoundary>
  );
};
