import React, { ReactElement } from "react";
import { Box, Flex, IconButton, PressableCard, Text } from "@vygruppen/spor-react";
import { DeleteOutline18Icon, EditOutline18Icon } from "@vygruppen/spor-icon-react";
import { useTranslation } from "react-i18next";

interface Props {
  onClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  icon: ReactElement;
  title: string;
  text: string;
  color: string;
}

const UserInfoCard: React.FC<Props> = ({ onClick, onEdit, onDelete, icon, title, text, color }) => {
  const { t } = useTranslation();

  const noHoverStyling = {
    width: "100%",
    "&:hover": {
      cursor: "default",
      boxShadow: "none",
      outlineColor: "platinum",
    },
  };

  return (
    <PressableCard
      as={onClick && "button"}
      onClick={onClick}
      variant="floating"
      padding="1rem"
      sx={onClick ? {} : noHoverStyling}
    >
      <Flex alignItems="center">
        <Box backgroundColor={color} borderRadius="50%" marginRight={2} padding={1}>
          {icon}
        </Box>

        <Box textAlign="left">
          <Text variant="xs" fontWeight="bold">
            {title}
          </Text>
          <Text variant="xs">{text}</Text>
        </Box>

        <Box ml="auto">
          {onEdit && (
            <IconButton
              aria-label={`${t("actions.edit")} ${title.toLowerCase()}`}
              variant="ghost"
              icon={<EditOutline18Icon />}
              ml="auto"
              size="sm"
              onClick={onEdit}
            />
          )}
          {onDelete && (
            <IconButton
              aria-label={`${t("actions.delete")} ${title.toLowerCase()}`}
              variant="ghost"
              icon={<DeleteOutline18Icon />}
              ml="auto"
              size="sm"
              onClick={onDelete}
            />
          )}
        </Box>
      </Flex>
    </PressableCard>
  );
};

export default UserInfoCard;
