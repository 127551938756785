import { useEffect, useState } from "react";
import { useGetRequest } from "../../api/hooks";
import { getApiUrl } from "../../api/config";
import { EventTypeDescriptor } from "../Types";

const useEventTypes = () => {
  const { getRequestStatus: getEventTypesStatus, getRequest: getEventTypesRequest } = useGetRequest<
    EventTypeDescriptor[]
  >(`${getApiUrl()}/events`);
  const [eventTypes, setEventTypes] = useState<EventTypeDescriptor[]>([]);

  useEffect(() => {
    if (getEventTypesStatus.status === "notAsked") getEventTypesRequest();
    if (getEventTypesStatus.status === "failure") setEventTypes([]);
    if (getEventTypesStatus.status === "success") {
      const data = getEventTypesStatus.data || [];
      setEventTypes(data);
    }
  }, [getEventTypesStatus.status]);

  return { eventTypes, getEventTypesStatus };
};

export default useEventTypes;
