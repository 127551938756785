import React from "react";
import { Button, Heading, Stack, Text } from "@vygruppen/spor-react";
import { useTranslation } from "react-i18next";
import subscriptionIllustration from "../../shared/assets/pushvarsel-mobil.svg";
import { useNavigate } from "react-router-dom";

const NoSubscriptions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Stack mt={25} gap={3} alignItems="center">
      <img
        src={subscriptionIllustration}
        alt="Illustration of a notification"
        style={{ maxWidth: "40%", height: "auto" }}
      />
      <Heading as="h2" variant="md" fontWeight="bold">
        {t("noSubscription")}
      </Heading>
      <Text variant="sm" textAlign="center">
        {t("noSubscriptionText")}
      </Text>
      <Button variant="secondary" size="md" onClick={() => navigate("/subscription/new")}>
        {t("subscriptionList.newSubscription")}
      </Button>
    </Stack>
  );
};
export default NoSubscriptions;
