import React, { createContext, ReactNode, useContext, useState } from "react";
import { User } from "../Types";

export type UserContextType = {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>;
  isAdmin: boolean;
  hasPhoneNumber: boolean;
};

export const UserContext = createContext<UserContextType | null>(null);

interface Props {
  children: ReactNode;
}

export const UserProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const hasPhoneNumber = user?.phoneNumber !== null && user?.phoneNumber !== undefined;

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        setIsAdmin,
        isAdmin,
        hasPhoneNumber,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("Du er utenfor User-konteksten!");
  }

  return context;
};
