import React from "react";
import { Button, Text } from "@vygruppen/spor-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../shared/contexts/AuthContext";
import { LoginContainer } from "./LoginContainer";

export const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { signInWithRedirect } = useAuth();

  const handleVyLogin = async () => {
    await signInWithRedirect();
  };

  const handleExternalLogin = () => {
    navigate("/external");
  };

  return (
    <LoginContainer>
      <Text as={"h1"} variant={"md"} fontWeight={"bold"} mb={1}>
        {t("applicationName")}
      </Text>
      <Text as={"h2"} variant={"sm"} mb={4}>
        {t("introPrompt")}{" "}
      </Text>
      <Button variant="primary" size="lg" width="100%" onClick={handleVyLogin} mb={4}>
        {t("logIn")}
      </Button>
      <Button variant="secondary" size="lg" width="100%" onClick={handleExternalLogin}>
        {t("logInExternal")}
      </Button>
    </LoginContainer>
  );
};
