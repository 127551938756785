import axios from "axios";
import { useEffect, useState } from "react";
import { requestConfigWithAuth } from "./config";
import { httpRequest, notAsked, RequestStatus } from "./httpRequest";
import { usePreviousValue } from "../shared/hooks/usePrevious";

/**
 * Hook for making and keeping track of HTTP GET requests.
 * @typeParam ResponseType - Response data type. If no ResponseType is defined, the response data will confirm to unknown.
 * @param url - Request url.
 * @returns A http get request method, and the request state.
 */
export const useGetRequest = <ResponseType,>(url: string) => {
  const [getRequestStatus, setGetRequestStatus] = useState<RequestStatus<ResponseType>>(notAsked());

  const get = async (): Promise<ResponseType> => {
    const config = await requestConfigWithAuth();
    const response = await axios.get<ResponseType>(url, config);
    return response.data;
  };

  const getRequest = () => {
    httpRequest<ResponseType>(get(), "GET", url, getRequestStatus, setGetRequestStatus);
  };

  return {
    getRequest,
    getRequestStatus,
    setGetRequestStatus,
  };
};

/**
 * Hook for making and keeping track of HTTP GET requests.
 * @typeParam ResponseType - Response data type. If no ResponseType is defined, the response data will confirm to unknown.
 * @param url - Request url.
 * @returns A http get request method, the request state and a method to update the request state manually.
 */
export const useGetImmediatelyRequest = <ResponseType,>(url: string) => {
  const [getRequestStatus, setGetRequestStatus] = useState<RequestStatus<ResponseType>>(notAsked());
  const previousUrl = usePreviousValue<string>(url);

  useEffect(() => {
    const get = async (): Promise<ResponseType> => {
      const config = await requestConfigWithAuth();
      const response = await axios.get<ResponseType>(url, config);
      return response.data;
    };

    const getRequest = () => {
      httpRequest<ResponseType>(get(), "GET", url, getRequestStatus, setGetRequestStatus);
    };

    const urlHasChanged = previousUrl !== undefined && previousUrl !== url;

    if (getRequestStatus.status === "notAsked" || urlHasChanged) getRequest();
  }, [getRequestStatus.status, url, previousUrl]);

  return {
    getRequestStatus,
    setGetRequestStatus,
  };
};

/**
 * Hook for making and keeping track of HTTP POST requests.
 * @typeParam ResponseType - Response data type. BodyType - Request body type. If no types are defined, the data will confirm to unknown.
 * @param url - Request url.
 * @returns A http post request method, and the request state.
 */
export const usePostRequest = <ResponseType, BodyType>(url: string) => {
  const [postRequestStatus, setPostRequestStatus] = useState<RequestStatus<ResponseType>>(
    notAsked()
  );

  const post = async (body?: BodyType): Promise<ResponseType> => {
    const config = await requestConfigWithAuth();
    const response = await axios.post<ResponseType>(url, body, config);
    return response.data;
  };

  const postRequest = (body?: BodyType) => {
    httpRequest<ResponseType>(post(body), "GET", url, postRequestStatus, setPostRequestStatus);
  };

  return {
    postRequest,
    postRequestStatus,
    setPostRequestStatus,
  };
};

export const usePatchRequest = <ResponseType, BodyType>(url: string) => {
  const [patchRequestStatus, setPatchRequestStatus] = useState<RequestStatus<ResponseType>>(
    notAsked()
  );

  const patch = async (body?: BodyType): Promise<ResponseType> => {
    const config = await requestConfigWithAuth();
    const response = await axios.patch<ResponseType>(url, body, config);
    return response.data;
  };

  const patchRequest = (body?: BodyType) => {
    httpRequest<ResponseType>(patch(body), "PATCH", url, patchRequestStatus, setPatchRequestStatus);
  };

  return {
    patchRequest,
    patchRequestStatus,
    setPatchRequestStatus,
  };
};

/**
 * Hook for making and keeping track of HTTP PUT requests.
 * @typeParam ResponseType - Response data type. BodyType - Request body type. If no types are defined, the data will confirm to unknown.
 * @param url - Request url.
 * @returns A http put request method, and the request state.
 */
export const usePutRequest = <ResponseType, BodyType>(url: string) => {
  const [putRequestStatus, setPutRequestStatus] = useState<RequestStatus<ResponseType>>(notAsked());

  const post = async (body?: BodyType): Promise<ResponseType> => {
    const config = await requestConfigWithAuth();
    const response = await axios.put<ResponseType>(url, body, config);
    return response.data;
  };

  const putRequest = (body?: BodyType) => {
    httpRequest<ResponseType>(post(body), "GET", url, putRequestStatus, setPutRequestStatus);
  };

  return {
    putRequest,
    putRequestStatus,
  };
};

/**
 * Hook for making and keeping track of HTTP DELETE requests.
 * @typeParam ResponseType - Response data type. If no ResponseType is defined, the response data will confirm to unknown.
 * @param url - Request url.
 * @returns A http delete request method, and the request state.
 */
export const useDeleteRequest = <ResponseType,>(url: string) => {
  const [deleteRequestStatus, setDeleteRequestStatus] = useState<RequestStatus<ResponseType>>(
    notAsked()
  );

  const remove = async (): Promise<ResponseType> => {
    const config = await requestConfigWithAuth();

    const response = await axios.delete<ResponseType>(url, config);
    return response.data;
  };

  const deleteRequest = () => {
    httpRequest<ResponseType>(remove(), "DELETE", url, deleteRequestStatus, setDeleteRequestStatus);
  };

  return {
    deleteRequest,
    deleteRequestStatus,
  };
};
