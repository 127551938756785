import "./App.css";
import { UnauthenticatedApp } from "UnauthenticatedApp";
import { AuthenticatedApp } from "AuthenticatedApp";
import { Language, LanguageProvider } from "@vygruppen/spor-react";
import { useTranslation } from "react-i18next";
import { useAuth } from "./shared/contexts/AuthContext";

function App() {
  const { i18n } = useTranslation();
  const { isAuthenticated } = useAuth();

  const content = isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />;
  return <LanguageProvider language={i18n.language as Language}>{content}</LanguageProvider>;
}

export default App;
