import React, { useEffect, useState } from "react";
import { Time } from "@vygruppen/spor-react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetRequest } from "../../api/hooks";
import { getApiUrl } from "../../api/config";
import { EventFilter, LineFilter, NotificationChannel, Subscription } from "shared/Types";
import { useSubscriptionData } from "../../shared/hooks/useSubscriptionData";
import useEventTypes from "../../shared/hooks/useEventTypes";
import useLines from "shared/hooks/useLines";
import ErrorModal from "../../shared/components/Modal/ErrorModal";
import { useSubscription } from "../../shared/contexts/SubscriptionContext";
import { notAsked } from "../../api/httpRequest";
import { useTranslation } from "react-i18next";
import { useUser } from "../../shared/contexts/UserContext";

export const EditSubscription = () => {
  const navigate = useNavigate();
  const { subscriptionId } = useParams();
  const { t } = useTranslation();
  const { eventTypes } = useEventTypes();
  const { lines } = useLines();
  const { user, isAdmin } = useUser();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const {
    updateSubscription,
    updateSubscriptionStatus,
    setUpdateSubscriptionStatus,
    getSubscriptions,
  } = useSubscription();

  const { getRequestStatus: oldSubscriptionData, getRequest: OldPostSubscriptionRequest } =
    useGetRequest<Subscription>(`${getApiUrl()}/subscription/${subscriptionId}`);

  useEffect(() => {
    if (updateSubscriptionStatus.status === "success") {
      getSubscriptions();
      setUpdateSubscriptionStatus(notAsked());
      navigate(`/subscription/${subscriptionId}`);
    }
    if (updateSubscriptionStatus.status === "failure") setIsErrorModalOpen(true);
  }, [updateSubscriptionStatus.status]);

  useEffect(() => {
    if (oldSubscriptionData.status === "notAsked") OldPostSubscriptionRequest();
    if (oldSubscriptionData.status === "success") {
      const data = oldSubscriptionData.data;
      const startEvents = data.eventFilters;
      const startStations = data.stationFilters;
      const startAreas = data.lineFilters;
      const channel = data.notificationChannel.includes("EMAIL_AND_SMS")
        ? ["EMAIL", "SMS"]
        : [data.notificationChannel];
      const [hourFrom, minuteFrom, secondFrom] = data.notificationInterval.start
        .split(":")
        .map(Number);
      const startTime = new Time(hourFrom, minuteFrom, secondFrom);
      const [hourTo, minuteTo, secondTo] = data.notificationInterval.end.split(":").map(Number);
      const endTime = new Time(hourTo, minuteTo, secondTo);
      const prevDays = data.notificationInterval.daysOfWeek;
      const allTrue = Object.values(prevDays).every(value => value);
      setDay(prevState => ({ ...prevState, ...prevDays, pickAll: allTrue }));
      setEvents(addAllEventFilters(startEvents));
      setLineFilters(addAllLineFilters(startAreas));
      setStationFilters(startStations);
      setTitle(data.name);
      setChannelChoice(channel);
      setTimeFrom(startTime);
      setTimeTo(endTime);
      setSelectedRecipients(data.recipients ?? []);
    }
  }, [oldSubscriptionData, eventTypes]);

  const addAllEventFilters = (existing: EventFilter[]) => {
    const filtersWithAll = existing.filter(filter => filter.filterValue === "ALL");
    const filters = existing.filter(filter => filter.filterValue !== "ALL");

    filtersWithAll.forEach(filter => {
      eventTypes
        .filter(event => event.group === filter.filterType)
        .forEach(event => filters.push({ filterType: event.group, filterValue: event.type }));
      filters.push(filter);
    });

    return filters;
  };
  const addAllLineFilters = (existing: LineFilter[]) => {
    if (existing.map(filter => filter.filterValue.id).includes("ALL")) {
      const lineFilters = lines.map(line => ({ filterValue: line } as LineFilter));
      lineFilters.push({
        filterValue: {
          id: "ALL",
          publicCode: "ALL",
          name: "ALL",
        },
      });
      return lineFilters;
    } else {
      return existing;
    }
  };

  const handleSave = () => {
    let notificationChannel: NotificationChannel;
    if (channelChoice.includes("SMS") && channelChoice.includes("EMAIL")) {
      notificationChannel = "EMAIL_AND_SMS";
    } else if (channelChoice.includes("SMS")) {
      notificationChannel = "SMS";
    } else {
      notificationChannel = "EMAIL";
    }
    const { pickAll, ...days } = day;
    const recipients = isAdmin ? selectedRecipients : [{ email: user!!.email, id: user!!.id }];
    const payload: Subscription = {
      id: Number(subscriptionId),
      name: title,
      isEnabled: true,
      eventFilters: checkForAllEvent(events),
      lineFilters: checkForAllLine(lineFilters),
      stationFilters: stationFilters,
      notificationChannel: notificationChannel,
      notificationInterval: {
        start: timeFrom.toString(),
        end: timeTo.toString(),
        daysOfWeek: days,
      },
      recipients: recipients,
    };
    updateSubscription(payload);
  };

  const {
    events,
    setEvents,
    lineFilters,
    setLineFilters,
    stationFilters,
    setStationFilters,
    channelChoice,
    setChannelChoice,
    timeFrom,
    setTimeFrom,
    timeTo,
    setTimeTo,
    title,
    setTitle,
    day,
    setDay,
    checkForAllEvent,
    checkForAllLine,
    stepper,
    content,
    selectedRecipients,
    setSelectedRecipients,
  } = useSubscriptionData({ handleSave, show: "edit" });

  return (
    <>
      {stepper}
      {content}
      {updateSubscriptionStatus.status === "failure" && (
        <ErrorModal
          isOpen={isErrorModalOpen}
          onClose={() => {
            setIsErrorModalOpen(false);
            setUpdateSubscriptionStatus(notAsked());
          }}
          errorMessage={t("errorMessages.failedToSaveSubscription") ?? undefined}
        />
      )}
    </>
  );
};
