import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  CheckboxGroup,
  ContentLoader,
  Divider,
  Language,
  Stack,
  Text,
} from "@vygruppen/spor-react";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { EventFilter, EventTypeDescriptor, TypeDescriptorCollection } from "shared/Types";
import useEventTypes from "../../shared/hooks/useEventTypes";
import DelayedLoading from "../../shared/components/Loading/DelayedLoading";
import FailureAlert from "../../shared/components/Alert/FailureAlert";
import { getCurrentLanguage } from "../../environment";

interface Props {
  events: EventFilter[];
  setEvents: Dispatch<SetStateAction<EventFilter[]>>;
}

export const Step1Content = ({ events, setEvents }: Props) => {
  const { t } = useTranslation();
  const { eventTypes, getEventTypesStatus } = useEventTypes();

  const typeDescriptors: TypeDescriptorCollection = {
    events: eventTypes,
  };

  const filterOutAlternativeTransportInSweden = (eventType: EventTypeDescriptor) =>
    !(getCurrentLanguage() === Language.Swedish && eventType.group === "ALTERNATIVE_TRANSPORT");

  const uniqueGroups = Array.from(
    new Set(
      typeDescriptors.events
        .filter(eventTypeDescriptor => filterOutAlternativeTransportInSweden(eventTypeDescriptor))
        .map(({ group }) => group)
    )
  );

  const getCheckedNum = (group: string) => {
    const filteredEventTypes = eventTypes.filter(event => event.group === group);
    return filteredEventTypes.reduce((count: number, eventType) => {
      return events.some(event => {
        return event.filterType === group && event.filterValue === eventType.type;
      })
        ? count + 1
        : count;
    }, 0);
  };

  const handleChange = (filterType: string, filterValues: (string | number)[]) => {
    const otherEventFilters = events.filter(event => event.filterType !== filterType);
    const eventFilters = filterValues.map(filterValue => ({
      filterType,
      filterValue: filterValue as string,
    }));

    const allChecked = eventTypes
      .filter(event => event.group === filterType)
      .every(eventType => filterValues.some(filterValue => filterValue === eventType.type));

    if (allChecked) {
      eventFilters.push({ filterType, filterValue: "ALL" });
    }

    setEvents([...otherEventFilters, ...eventFilters]);
  };

  const hasLabelForCurrentLanguage = (eventType: EventTypeDescriptor) => {
    const currentLanguage = getCurrentLanguage();

    switch (currentLanguage) {
      case Language.Swedish:
        return !!eventType.label.SWE;
      case Language.NorwegianBokmal:
        return !!eventType.label.NOB;
      default:
        return false;
    }
  };

  const groupedLabels = (group: string) => {
    const ev = eventTypes.filter(event => event.group === group);
    return (
      <AccordionPanel>
        <Box mb={1}>{renderSelectAllButton(group)}</Box>
        <CheckboxGroup
          value={events
            .filter(event => event.filterType === group && event.filterValue !== "ALL")
            .map(event => event.filterValue)}
          onChange={filterValues => handleChange(group, filterValues)}
          direction={"column"}
        >
          {ev
            .filter(eventType => hasLabelForCurrentLanguage(eventType))
            .map(eventType => (
              <Checkbox key={eventType.type} value={eventType.type}>
                {getCurrentLanguage() === Language.Swedish
                  ? eventType.label.SWE
                  : eventType.label.NOB}
              </Checkbox>
            ))}
        </CheckboxGroup>
      </AccordionPanel>
    );
  };

  const renderSelectAllButton = (group: string) => {
    const allChecked = eventTypes
      .filter(event => event.group === group)
      .every(eventType =>
        events.some(event => event.filterType === group && event.filterValue === eventType.type)
      );

    const handleSelectAll = (selectAll: boolean) => {
      const otherFilters = events.filter(event => event.filterType !== group);
      if (selectAll) {
        const filters = eventTypes
          .filter(event => event.group === group)
          .map(event => ({ filterType: event.group, filterValue: event.type }));
        filters.push({ filterType: group, filterValue: "ALL" });
        setEvents([...otherFilters, ...filters]);
      } else {
        setEvents(otherFilters);
      }
    };

    return (
      <Checkbox
        isChecked={allChecked}
        onChange={e => handleSelectAll(e.target.checked)}
        value={"ALL"}
      >
        {t("actions.selectAll")}
      </Checkbox>
    );
  };

  const getContent = () => {
    switch (getEventTypesStatus.status) {
      case "success":
        return (
          <Accordion variant="ghost" allowToggle>
            <Stack spacing={1} divider={<Divider />}>
              {uniqueGroups.map(group => (
                <React.Fragment key={group}>
                  <AccordionItem>
                    <AccordionButton fontWeight={"bold"}>
                      {`${t<string>(group)} (${getCheckedNum(group)})`} <AccordionIcon />
                    </AccordionButton>
                    {groupedLabels(group)}
                  </AccordionItem>
                </React.Fragment>
              ))}{" "}
            </Stack>
            <Divider borderBottomWidth="1px" />
          </Accordion>
        );
      case "loading":
      case "notAsked":
        return (
          <DelayedLoading delay={600}>
            <ContentLoader />
          </DelayedLoading>
        );
      case "failure":
        return (
          <FailureAlert variant="error" errorMessage={t("errorMessages.errorFetchingEventTypes")} />
        );
    }
  };

  return (
    <>
      <Text variant="sm" fontWeight={"400"} marginTop={"4vh"} marginBottom={"2vh"}>
        {t("subscription.newSubscriptionPrompt")}{" "}
      </Text>
      {getContent()}
    </>
  );
};
