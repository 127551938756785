import React, { useState } from "react";
import { FormControl, FormErrorMessage, FormHelperText, Input, Text } from "@vygruppen/spor-react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Location } from "history";
import { LoginContainer } from "./LoginContainer";
import { useAuth } from "../../shared/contexts/AuthContext";
import { NewPasswordForm } from "./NewPasswordForm";

interface LocationState {
  email?: string;
}

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { state } = useLocation() as Location<LocationState>;
  const { confirmResetPassword, signIn } = useAuth();
  const { t } = useTranslation();
  const [code, setCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const email = state?.email ?? "";
  if (email === "") navigate("/");

  const resetPassword = async (newPassword: string) => {
    setErrorMessage(undefined);
    setIsLoading(true);
    const result = await confirmResetPassword(email, newPassword, code.trim());
    if (result.message === "ResetPasswordWrongCode") {
      setIsLoading(false);
      setErrorMessage(t("invalidCode"));
    }
    if (result.message === "ResetPasswordFailed") {
      setIsLoading(false);
      setErrorMessage(t("resetPasswordError"));
    } else {
      completeSignIn(email, newPassword);
    }
  };

  const completeSignIn = (email: string, password: string) => {
    signIn(email, password)
      .then(() => {
        setIsLoading(false);
        setErrorMessage(undefined);
        navigate("/login-redirect");
      })
      .catch(() => {
        setErrorMessage(t("loginError"));
      });
  };

  return (
    <LoginContainer hasVisibleBackground={true}>
      <Text as="h1" variant="md" fontWeight="bold" mb={1}>
        {t("applicationName")}
      </Text>
      <Text variant="sm" mb={4}>
        {t("administratorPasswordReset")}
      </Text>
      <FormControl mb={1.5} isInvalid={code.length !== 0 && code.length !== 6}>
        <FormHelperText color="black">{t("codeHelperText")}</FormHelperText>
        <Input label="Kode" value={code} onChange={e => setCode(e.target.value)} />
        <FormErrorMessage>{t("codeMustHaveLengthSix")}</FormErrorMessage>
      </FormControl>
      <NewPasswordForm
        handleNewPassword={resetPassword}
        isLoading={isLoading}
        errorMessage={errorMessage}
        disableSubmitButton={code.length !== 6}
      />
    </LoginContainer>
  );
};
