import React from "react";
import { Flex, Heading } from "@vygruppen/spor-react";
import vyLogo from "../../assets/vy.logo.final_primary.svg";
import Profile from "../Profile/Profile";
import { useTranslation } from "react-i18next";
import tokens from "@vygruppen/spor-design-tokens";

const Header: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      borderBottom="1px solid rgba(0, 0, 0, 0.2)"
      position="fixed"
      backgroundColor={tokens.color.alias.white}
      top={0}
      zIndex={1}
      height="60px"
    >
      <Flex alignItems="center">
        <img src={vyLogo} alt="Vy Logo" />
        <Heading as="h1" variant="sm">
          {t("applicationName")}
        </Heading>
      </Flex>
      <Profile />
    </Flex>
  );
};
export default Header;
