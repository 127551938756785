import React from "react";
import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
} from "@vygruppen/spor-react";
import errorImage from "../../assets/feilmelding.svg";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  errorMessage?: string;
}

const ErrorModal: React.FC<Props> = ({ isOpen, onClose, errorMessage }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalContent borderRadius={0}>
        <ModalHeader>
          <Heading as="h2" variant="md">
            {errorMessage ? errorMessage : t("errorMessages.somethingWentWrong")}
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Stack alignItems="center" justifyContent="space-around" paddingTop="3rem">
            <img
              src={errorImage}
              alt="Conductor standing next to an error-icon"
              style={{ maxWidth: "80%", height: "45vh" }}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={onClose}>
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ErrorModal;
