import { getApiUrl } from "api/config";
import { useGetRequest, usePostRequest } from "api/hooks";
import { RequestStatus } from "api/httpRequest";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { NewSubscription, Subscription } from "../Types";

export type SubscriptionContextType = {
  subscriptions: Subscription[];
  getSubscriptions: () => void;
  getSubscriptionsStatus: RequestStatus<Subscription[]>;
  saveSubscription: (subscription: NewSubscription) => void;
  saveSubscriptionStatus: RequestStatus<Subscription>;
  setSaveSubscriptionStatus: Dispatch<SetStateAction<RequestStatus<Subscription>>>;
  updateSubscription: (subscription: Subscription) => void;
  updateSubscriptionStatus: RequestStatus<Subscription>;
  setUpdateSubscriptionStatus: Dispatch<SetStateAction<RequestStatus<Subscription>>>;
};

export const SubscriptionContext = createContext<SubscriptionContextType | null>(null);

interface Props {
  children: ReactNode;
}

export const SubscriptionProvider: React.FC<Props> = ({ children }) => {
  const { getRequestStatus: getSubscriptionsStatus, getRequest: getSubscriptions } = useGetRequest<
    Subscription[]
  >(`${getApiUrl()}/subscription`);

  const {
    postRequest: saveSubscription,
    postRequestStatus: saveSubscriptionStatus,
    setPostRequestStatus: setSaveSubscriptionStatus,
  } = usePostRequest<Subscription, NewSubscription>(`${getApiUrl()}/subscription`);
  const {
    postRequest: updateSubscription,
    postRequestStatus: updateSubscriptionStatus,
    setPostRequestStatus: setUpdateSubscriptionStatus,
  } = usePostRequest<Subscription, Subscription>(`${getApiUrl()}/subscription/update`);

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  useEffect(() => {
    if (getSubscriptionsStatus.status === "notAsked") getSubscriptions();
    if (getSubscriptionsStatus.status === "success") {
      const data = getSubscriptionsStatus.data || [];
      setSubscriptions(data);
    }
  }, [getSubscriptionsStatus.status]);

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptions,
        getSubscriptions,
        getSubscriptionsStatus,
        saveSubscription,
        saveSubscriptionStatus,
        setSaveSubscriptionStatus,
        updateSubscription,
        updateSubscriptionStatus,
        setUpdateSubscriptionStatus,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => {
  const context = useContext(SubscriptionContext);

  if (!context) {
    throw new Error("Du er utenfor Subscription-konteksten!");
  }

  return context;
};
