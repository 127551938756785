import React from "react";
import { StaticAlert, Text, TextLink } from "@vygruppen/spor-react";
import { useTranslation } from "react-i18next";

interface Props {
  variant: "warning" | "error";
  errorMessage: string;
}

const FailureAlert: React.FC<Props> = ({ variant, errorMessage }) => {
  const { t } = useTranslation();
  return (
    <StaticAlert variant={variant}>
      <Text variant="sm" textAlign="left">
        {errorMessage}{" "}
        <TextLink variant="secondary" href="mailto:Team-Traffic-Control@vy.no" isExternal>
          {t("contactIT")}
        </TextLink>
      </Text>
    </StaticAlert>
  );
};

export default FailureAlert;
