import { Flex } from "@vygruppen/spor-react";
import logo from "../../shared/assets/vy-logo-white.svg";
import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import background from "../../shared/assets/Bergensbanen.jpeg";
import tokens from "@vygruppen/spor-design-tokens";

const Container = styled.div`
  height: 100%;
  background: url(${background});
  background-size: cover;
  background-position: 40% center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: ${tokens.color.alias.white};
  .chakra-form-control {
    color: initial;
  }
`;

interface Props {
  children: ReactNode;
  hasVisibleBackground?: boolean;
}

export const LoginContainer: React.FC<Props> = ({ children, hasVisibleBackground }) => {
  return (
    <Container>
      <img
        src={logo}
        style={{
          height: "8vh",
          opacity: "0.6",
          position: "fixed",
          top: "40%",
          left: "7%",
        }}
        alt="logo"
      />
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        flexDirection="column"
        width={`min(85%, 500px)`}
        color={hasVisibleBackground ? tokens.color.alias.black : tokens.color.alias.white}
        padding={5}
        borderRadius="5%"
        mb={4}
        background={hasVisibleBackground ? "white" : "none"}
        zIndex={tokens.depth["z-index"].modal}
      >
        {children}
      </Flex>
    </Container>
  );
};
