import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { ContentContainer } from "./ContentContainer";
import Header from "../Header/Header";

const Wrapper = styled.div`
  height: 100%;
  background-color: #ffffff;
`;

interface Props {
  children: ReactNode;
}

export const MainContainer: React.FC<Props> = ({ children }) => {
  return (
    <Wrapper>
      <Header />
      <ContentContainer pt={60} pb={3}>
        {children}
      </ContentContainer>
    </Wrapper>
  );
};
