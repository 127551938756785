import React, { useEffect, useState } from "react";
import { Heading, Stack } from "@vygruppen/spor-react";
import { EMailOutline24Icon, PhoneSignalOutline24Icon } from "@vygruppen/spor-icon-react";
import tokens from "@vygruppen/spor-design-tokens";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../shared/contexts/UserContext";
import RegisterPhoneNumberModal from "../../../shared/components/Modal/RegisterPhoneNumberModal";
import { getPhoneNumberString } from "../../../shared/Utils";
import UserInfoCard from "./UserInfoCard";
import { useDeleteRequest } from "../../../api/hooks";
import { getApiUrl } from "../../../api/config";
import { getCurrentCountryCode } from "../../../environment";

const UserInfo: React.FC = () => {
  const { t } = useTranslation();
  const { user, hasPhoneNumber, setUser } = useUser();
  const [isRegisterPhoneNumberModalOpen, setRegisterPhoneNumberModalOpen] = useState(false);

  const { deleteRequest: deleteNumber, deleteRequestStatus: deleteNumberStatus } = useDeleteRequest(
    `${getApiUrl()}/user/delete-phone-number`
  );

  useEffect(() => {
    if (user && deleteNumberStatus.status === "success") {
      setUser({ ...user, phoneNumber: null });
    }
  }, [deleteNumberStatus]);

  const userInfoCard = () => {
    if (user && hasPhoneNumber) {
      return (
        <UserInfoCard
          icon={<PhoneSignalOutline24Icon />}
          title={t("phoneNumber.phoneNumber")}
          text={getPhoneNumberString(user)}
          color={tokens.color.alias.blonde}
          onEdit={() => setRegisterPhoneNumberModalOpen(true)}
          onDelete={deleteNumber}
        />
      );
    }
    return (
      <UserInfoCard
        onClick={() => setRegisterPhoneNumberModalOpen(true)}
        icon={<PhoneSignalOutline24Icon />}
        title={t("phoneNumber.addPhoneNumber")}
        text={t("phoneNumber.addPhoneNumberText")}
        color={tokens.color.alias.blonde}
      />
    );
  };

  return user !== null ? (
    <>
      <Stack gap={1} mt={5}>
        <Heading as="h2" variant="sm" fontWeight="bold">
          {t("userInfo.notificationGetsSentTo")}
        </Heading>
        <UserInfoCard
          icon={<EMailOutline24Icon />}
          title={t("userInfo.email")}
          text={user.email}
          color={tokens.color.alias.seaMist}
        />
        {getCurrentCountryCode() !== "SE" && userInfoCard()}
      </Stack>

      <RegisterPhoneNumberModal
        isOpen={isRegisterPhoneNumberModalOpen}
        setOpen={setRegisterPhoneNumberModalOpen}
      />
    </>
  ) : null;
};

export default UserInfo;
