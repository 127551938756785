import React, { useState } from "react";
import { Text } from "@vygruppen/spor-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LoginContainer } from "./LoginContainer";
import { useAuth } from "../../shared/contexts/AuthContext";
import { NewPasswordForm } from "./NewPasswordForm";

export const ChangePassword = () => {
  const navigate = useNavigate();
  const { confirmSignIn } = useAuth();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const confirmChangePassword = async (newPassword: string) => {
    setErrorMessage(undefined);
    setIsLoading(true);
    const result = await confirmSignIn(newPassword);
    setIsLoading(false);
    if (result.message === "SetNewPasswordFailed") {
      setErrorMessage(t("changePasswordError"));
    } else {
      navigate("/login-redirect");
    }
  };

  return (
    <LoginContainer>
      <Text as="h1" variant="md" fontWeight="bold" mb={1}>
        {t("applicationName")}
      </Text>
      <Text as="h2" variant="sm" mb={4}>
        {t("setNewPasswordPrompt")}
      </Text>
      <NewPasswordForm
        handleNewPassword={confirmChangePassword}
        isLoading={isLoading}
        errorMessage={errorMessage}
      />
    </LoginContainer>
  );
};
