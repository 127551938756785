import {
  Box,
  Checkbox,
  CheckboxGroup,
  ChoiceChip,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  Text,
  Time,
  TimePicker,
  SearchInput,
} from "@vygruppen/spor-react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { NoRegisteredPhoneNumberWarning } from "./NoRegisteredPhoneNumberWarning";
import { useUser } from "../../shared/contexts/UserContext";
import { DaysOfWeekWithPickAll, Recipient } from "../../shared/Types";
import { RecipientList } from "../Recipient/RecipientList";
import { getCurrentCountryCode } from "../../environment";

interface Props {
  day: DaysOfWeekWithPickAll;
  setDay: Dispatch<SetStateAction<DaysOfWeekWithPickAll>>;
  timeFrom: Time;
  setTimeFrom: Dispatch<SetStateAction<Time>>;
  timeTo: Time;
  setTimeTo: Dispatch<SetStateAction<Time>>;
  daysOfWeek: DaysOfWeekWithPickAll;
  isInvalidTime: boolean;
  setIsInvalidTime: Dispatch<SetStateAction<boolean>>;
  channelChoice: string[];
  setChannelChoice: Dispatch<SetStateAction<string[]>>;
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  selectedRecipients: Recipient[];
  setSelectedRecipients: Dispatch<SetStateAction<Recipient[]>>;
}

export const Step3Content = ({
  day,
  setDay,
  timeFrom,
  timeTo,
  setTimeFrom,
  setTimeTo,
  isInvalidTime,
  setIsInvalidTime,
  channelChoice,
  setChannelChoice,
  title,
  setTitle,
  selectedRecipients,
  setSelectedRecipients,
}: Props) => {
  const { t } = useTranslation();
  const { hasPhoneNumber, isAdmin } = useUser();
  const [query, setQuery] = useState("");

  const onTimeFromChange = (time: Time) => {
    time.compare(timeTo) >= 0 ? setIsInvalidTime(true) : setIsInvalidTime(false);
    setTimeFrom(time);
  };

  const onTimeToChange = (time: Time) => {
    time.compare(timeFrom) <= 0 ? setIsInvalidTime(true) : setIsInvalidTime(false);
    setTimeTo(time);
  };

  const handleChannelChange = (boxKey: string) => {
    if (channelChoice.includes(boxKey)) {
      setChannelChoice(channelChoice => channelChoice.filter(c => c !== boxKey));
    } else {
      setChannelChoice([...channelChoice, boxKey]);
    }
  };

  const toggleRecipient = (id: number, email: string) => {
    setSelectedRecipients(previouslySelectedRecipients => {
      return previouslySelectedRecipients.some(recipient => recipient.id === id)
        ? previouslySelectedRecipients.filter(recipient => recipient.id !== id)
        : [...previouslySelectedRecipients, { id, email }];
    });
  };

  const removeRecipient = (id: number) => {
    setSelectedRecipients(previouslySelectedRecipients => {
      return previouslySelectedRecipients.filter(recipient => recipient.id !== id);
    });
  };

  const onChangeDay = (day: keyof DaysOfWeekWithPickAll) => {
    if (day === "pickAll") {
      setDay((prevState: DaysOfWeekWithPickAll) => {
        const allSelected = Object.values(prevState).every(item => item);
        return Object.keys(prevState).reduce((acc, key) => {
          return { ...acc, [key]: !allSelected };
        }, {} as DaysOfWeekWithPickAll);
      });
    } else {
      setDay(prevState => {
        const updatedDays = { ...prevState, [day]: !prevState[day] };
        const allSelected = Object.values(prevState).every(item => item);
        if (allSelected) {
          updatedDays.pickAll = false;
        }
        return updatedDays;
      });
    }
  };

  return (
    <Flex direction={"column"} justifyContent={"space-between"} height={"100%"}>
      <Box>
        <Text variant="sm" fontWeight={"bold"} marginTop={"4vh"}>
          {isAdmin
            ? t("subscriptionPreference.adminNewSubscriptionTime")
            : t("subscriptionPreference.newSubscriptionTime")}
        </Text>

        <FormControl isInvalid={isInvalidTime} mt={2}>
          <Flex gap={2}>
            <TimePicker
              id="timeFrom"
              label={t("from")}
              value={timeFrom}
              onChange={timeFrom =>
                onTimeFromChange(new Time(timeFrom?.hour ?? 0, timeFrom?.minute ?? 0))
              }
            ></TimePicker>{" "}
            <TimePicker
              id="timeTo"
              label={t("to")}
              value={timeTo}
              onChange={timeTo => onTimeToChange(new Time(timeTo?.hour ?? 0, timeTo?.minute ?? 0))}
            ></TimePicker>{" "}
          </Flex>
          <FormErrorMessage>{t("errorMessages.timeErrorMessage")}</FormErrorMessage>
        </FormControl>

        <Stack flexDirection={"row"} flexWrap={"wrap"} paddingTop={2} paddingBottom={2} gap={0.5}>
          {(Object.entries(day) as [keyof DaysOfWeekWithPickAll, boolean][]).map(
            ([day, checked]) => (
              <ChoiceChip
                key={day}
                isChecked={checked}
                size="xs"
                onChange={_ => {
                  onChangeDay(day);
                }}
              >
                {t(`weekday.${day}`)}
              </ChoiceChip>
            )
          )}
        </Stack>
        <Stack gap={2}>
          <Text marginTop={"5vh"} variant={"sm"} fontWeight={"bold"}>
            {t("title")}
          </Text>
          <Input label={t("title")} value={title} onChange={e => setTitle(e.target.value)} />
          <Text variant={"xs"}>{t("subscription.titlePrompt")}</Text>
        </Stack>
        {getCurrentCountryCode() === "NO" && !isAdmin && (
          <>
            <Text marginTop={"5vh"} marginBottom={1.5} fontWeight={"bold"}>
              {t("subscriptionPreference.newSubscriptionChannel")}
            </Text>
            <Container paddingLeft={"12px"}>
              <CheckboxGroup value={channelChoice} direction={"column"}>
                <Checkbox key={"SMS"} value={"SMS"} onChange={() => handleChannelChange("SMS")}>
                  SMS
                </Checkbox>
                <Checkbox
                  key={"EMAIL"}
                  value={"EMAIL"}
                  onChange={() => handleChannelChange("EMAIL")}
                >
                  Epost
                </Checkbox>
              </CheckboxGroup>
            </Container>
          </>
        )}
        {isAdmin && (
          <Stack gap={4}>
            <Flex justifyContent="space-between" mt="5vh" gap={2} flexWrap="wrap">
              <Text fontWeight="bold">{t("subscriptionPreference.subscriptionRecipients")}</Text>
              <Box width={{ base: "100%", md: "340px" }} flexGrow={{ base: 1, md: 0 }}>
                <SearchInput
                  label={t("searchRecipientInputLabel")}
                  value={query}
                  onChange={e => {
                    setQuery(e.target.value);
                  }}
                />
              </Box>
            </Flex>
            <RecipientList
              selectedRecipients={selectedRecipients}
              removeSelectedRecipient={removeRecipient}
              toggleRecipient={toggleRecipient}
              query={query}
            />
          </Stack>
        )}
      </Box>
      {channelChoice.includes("SMS") && !hasPhoneNumber && (
        <Box mt={2}>
          <NoRegisteredPhoneNumberWarning />
        </Box>
      )}
    </Flex>
  );
};
