export const nb = {
  applicationName: "Varslinger",
  title: "Tittel",
  profile: "Profil",
  addingUser: "Legger deg til som ny bruker",
  contactIT: "Kontakt IT hvis feilen vedvarer",
  errorAddingUser: "Klarte ikke å legge deg til som ny bruker.",
  loadingUser: "Henter brukerdata",
  logIn: "Logg inn",
  logInExternal: "Innlogging for eksterne",
  logInExternalPrompt:
    "Ta kontakt med Team-Traffic-Control@vy.no dersom du har problemer med innlogging eller mangler tilgang.",
  logInExternalEmailLabel: "E-post",
  logInExternalPasswordLabel: "Passord",
  changePasswordError:
    "Klarte ikke å sette nytt passord. Forsøk å gå tilbake til innloggingssiden og prøv på nytt.",
  externalLogInError: "Klarte ikke å logge deg inn på grunn av en feil i systemet.",
  externalLogInWrongPassword: "Feil passord eller brukernavn.",
  externalLogInExceededAttempts:
    "Du har brukt for mange forsøk på å logge inn med feil passord, prøv igjen senere.",
  logoutError: "Klarte ikke å logge ut.",
  loginError: "Klarte ikke å logge inn.",
  newPasswordInputLabel: "Nytt passord",
  confirmPasswordInputLabel: "Bekreft nytt passord",
  invalidPassword:
    "Ugyldig passord, passordet må inneholde minst 12 tegn, minst én liten bokstav, én stor bokstav, et tall og et spesialtegn.",
  invalidCode: "Koden du tastet inn var feil, verifiser at du har riktig kode og prøv igjen.",
  resetPasswordError: "Klarte ikke å tilbakestille passordet ditt, prøv igjen senere.",
  notMatchingPasswords: "Passordene må være like.",
  codeMustHaveLengthSix: "Koden må være på 6 tegn",
  codeHelperText: "Skriv inn koden du har fått på e-post",
  setNewPassword: "Sett nytt passord",
  setNewPasswordPrompt: "Ved første innlogging må du lage et nytt passord.",
  administratorPasswordReset:
    "En administrator har tilbakestilt passordet ditt for deg. Dersom du mener dette er feil ta kontakt med Team-Traffic-Control@vy.no. Du skal ha mottatt en e-post med kode.",
  logOut: "Logg ut",
  "logOut?": "Vil du logge ut av Varslinger?",
  noAccess: "Du har ikke tilgang",
  welcomeUser: "Velkommen som ny bruker! Du blir snart sendt videre inn i systemet",
  noSubscription: "Ingen faste varsler",
  noSubscriptionText:
    "Sett opp et varsel for å få beskjed om hendelser i togtrafikken for spesifikke tog eller strekninger.",
  recurringSubscription: "Mine faste varsler",
  introPrompt:
    "Her kan du sette opp varslinger for å motta informasjon når det skjer ting i trafikken.",
  subscriptionList: {
    addSubscription: "Legg til",
    newSubscription: "Opprett ny varsling",
    loading: "Henter varslinger",
  },
  subscription: {
    newSubscription: "Nytt varsel",
    newSubscriptionPrompt:
      "Velg hvilke typer hendelser du ønsker å motta varsel om. Du kan enkelt endre på dette senere om du ønsker det.",
    titlePrompt: "Legg til tittel for varselet.",
    allEvents: "Alle hendelser",
    allLines: "Alle togserier/linjer",
    none: "Ingen valgt",
  },
  subscriptionPreference: {
    newSubscriptionFilters:
      "Du kan spesifisere varslene ved å huke av hvilke togserier/linjer du ønsker å varsles for.",
    newSubscriptionTime: "Jeg ønsker å varsles om hendelser i tidsrommet",
    adminNewSubscriptionTime: "Jeg vil sette opp et varsel for hendelser i tidsrommet",
    newSubscriptionChannel: "Jeg ønsker å varsles via",
    subscriptionRecipients: "Velg mottakere",
    pauseOption: "Varsler kan pauses midlertidig fra hjem-skjermen",
  },
  phoneNumber: {
    phoneNumber: "Telefonnummer",
    addPhoneNumber: "Registrer telefonnummer",
    addPhoneNumberText: "Dersom du vil varsles på SMS",
    registerPhoneNumberHere: "Registrer telefonnummer her",
    couldNotFindNumber: "Vi kunne ikke finne ditt telefonnummer i våre registere.",
    validatePhoneNumber: "Du må fylle ut et telefonnummer",
    sendVerificationCode: "Send verifiseringskode",
    verificationCodeHasBeenSent: "En verifiseringskode er sendt til {{phoneNumber}}",
    enterVerificationCode: "Skriv inn verifiseringskode",
    verifyAndSave: "Verifiser telefonnummer",
    verificationFailed: "Verifisering feilet for nummer {{phoneNumber}}",
    errorSaving: "Noe gikk galt under lagring av telefonnummer",
    editPhoneNumber: "Rediger telefonnummer",
    tryAgain: "Prøv igjen",
    smsSendingIsDisabled: "SMS utsending er midlertidig utilgjengelig. Prøv igjen senere.",
    errorGeneratingCode: "Noe gikk galt under sending av verifiseringskode.",
  },
  userInfo: {
    notificationGetsSentTo: "Varsler sendes til",
    email: "Epost",
  },
  recipient: {
    noRecipients: "Du har ingen mottakere. Legg til en mottaker under for å få opp valg.",
    addRecipient: "Legg til ny mottaker",
    addRecipientButtonText: "Ny mottaker",
    editRecipientInputLabel: "Ny e-postadresse",
    addRecipientInputLabel: "E-postadresse",
    addRecipientError: "Klarte ikke å lagre mottakeren, noe gikk galt.",
    duplicateRecipientEmailError: "Mottakeren finnes allerede",
    invalidRecipientEmailError:
      "Ugyldig e-postadresse. Vennligst skriv inn en gyldig e-postadresse (f.eks. olanordmann@vy.no).",
    deleteRecipientError: "Klarte ikke å slette mottaker",
    editRecipientError: "Klarte ikke å lagre endringer",
    confirmDeleteTitle: "Er du sikker på at du vil slette mottakeren?",
    confirmDeleteText:
      "Når du sletter {{email}} vil mottakeren bli slettet fra alle varsler du har laget. Hvis du kun vil fjerne brukeren fra dette varselet kan du bare fjerne haken ved siden av e-posten.",
    closeConfirmDelete: "Avbryt",
    confirmDeleteButton: "Bekreft sletting",
    editEmailAriaLabel: "Endre e-post",
    deleteEmailAriaLabel: "Slett e-post",
    saveChangesAriaLabel: "Lagre endringer",
    cancelWithoutSaveAriaLabel: "Avslutt redigering uten å lagre",
  },
  actions: {
    ok: "Ok",
    next: "Neste",
    back: "Tilbake",
    save: "Lagre",
    delete: "Slett",
    goBack: "Gå tilbake til forsiden",
    edit: "Rediger",
    editSubscription: "Rediger varsel",
    deleteSubscription: "Slett varsel",
    cancel: "Avbryt",
    "sure?": "Er du sikker på at du vil slette varselet?",
    selectAll: "Velg alle",
  },
  weekday: {
    pickAll: "velg alle",
    monday: "mandag",
    tuesday: "tirsdag",
    wednesday: "onsdag",
    thursday: "torsdag",
    friday: "fredag",
    saturday: "lørdag",
    sunday: "søndag",
    monday_short: "man",
    tuesday_short: "tir",
    wednesday_short: "ons",
    thursday_short: "tor",
    friday_short: "fre",
    saturday_short: "lør",
    sunday_short: "søn",
  },
  TRAIN: "Tog",
  INFRASTRUCTURE: "Infrastruktur",
  STATION: "Stasjon",
  ALTERNATIVE_TRANSPORT: "Alternativ transport",
  LINE: "Togserier/linjer",
  STRETCH: "Strekninger",
  ACTIONS: "Hendelser",
  EMAIL: "E-postvarsel",
  SMS: "SMS-varsel",
  EMAIL_AND_SMS: "SMS- og epostvarsel",
  emailRecipients: "E-post mottakere",
  stationFilter: "Stasjoner",
  stationSelect: {
    selectStation: "Velg stasjon",
    noneSelected:
      "Ingen stasjoner er valgt ennå. Velg stasjoner ved å søke på stasjonsnavn i inputfeltet over",
    selected: "valgt",
    removeStation: "Fjern stasjon",
    filterExplanation:
      "Du kan spesifisere stasjoner som du ønsker å motta varsler for dersom noe skjer på en av stasjonene.",
  },
  errorMessages: {
    timeErrorMessage: "Tid fra må være før tid til",
    somethingWentWrong: "Noe gikk galt",
    failedToSaveSubscription: "Klarte ikke å lagre varslingen",
    errorFetchingSubscriptions: "Klarte ikke å hente varslene dine.",
    errorFetchingSubscription: "Klarte ikke å hente varselet.",
    errorFindingSubscription: "Klarte ikke å finne varselet.",
    errorUpdatingSubscription: "Klarte ikke å oppdatere varslingen",
    errorFetchingEventTypes: "Klarte ikke å hente hendelser.",
    errorFetchingLines: "Klarte ikke å hente linjer.",
    eventTypesForSubscription: "Klarte ikke å hente hendelser for varselet.",
    errorDeletingSubscription: "Klarte ikke å slette varselet.",
    errorFetchingRecipients: "Klarte ikke å hente mottakere",
  },
  to: "Til",
  from: "Fra",
  searchRecipientInputLabel: "Søk etter mottaker",
  selected: "valgt",
};
