import React, { useState } from "react";
import {
  Box,
  Button,
  ContentLoader,
  Heading,
  IconButton,
  Language,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@vygruppen/spor-react";
import { useTranslation } from "react-i18next";
import {
  ArrowLeftOutline24Icon,
  DeleteOutline18Icon,
  EditOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { useNavigate, useParams } from "react-router-dom";
import { MainContainer } from "../../shared/components/Container/MainContainer";
import DeleteSubscriptionModal from "../SubscriptionDelete/DeleteSubscriptionModal";
import formatSubscription from "../../shared/hooks/useFormatSubscriptionData";
import useEventTypes from "../../shared/hooks/useEventTypes";
import {
  EventFilter,
  EventTypeDescriptor,
  LineFilter,
  Recipient,
  StationFilter,
  Subscription,
} from "shared/Types";
import { useSubscription } from "../../shared/contexts/SubscriptionContext";
import DelayedLoading from "../../shared/components/Loading/DelayedLoading";
import FailureAlert from "../../shared/components/Alert/FailureAlert";
import { useUser } from "../../shared/contexts/UserContext";
import { getCurrentCountryCode, getCurrentLanguage } from "../../environment";
import { useStations } from "../../shared/contexts/StationsContext";

const SubscriptionInformation = () => {
  const { formatSubscriptionInterval } = formatSubscription();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const { isAdmin } = useUser();
  const { subscriptionId } = useParams();
  const pageId = Number(subscriptionId);

  const { eventTypes, getEventTypesStatus } = useEventTypes();
  const { subscriptions, getSubscriptionsStatus } = useSubscription();
  const { stations } = useStations();

  const subscription = subscriptions.find(subscription => subscription.id === pageId);
  const eventGroups = Array.from(new Set(eventTypes.map(event => event.group)));

  const renderCorrectLanguage = (event: EventTypeDescriptor) => {
    return getCurrentLanguage() === Language.Swedish ? event.label["SWE"] : event.label["NOB"];
  };

  const renderRecipients = (recipients: Recipient[]) => {
    return (
      <UnorderedList ml={4} aria-label={t("emailRecipients")}>
        {recipients.map(recipient => {
          return (
            <ListItem key={recipient.id}>
              <Text variant="sm">{recipient.email}</Text>
            </ListItem>
          );
        })}
      </UnorderedList>
    );
  };

  const renderEventFilters = (group: string, eventFilters: EventFilter[]) => {
    const filtersInGroup = eventFilters.filter(eventFilter => eventFilter.filterType === group);
    if (filtersInGroup.some(eventFilter => eventFilter.filterValue === "ALL")) {
      return (
        <UnorderedList ml={4} aria-label={t<string>(group)}>
          <ListItem>
            <Text variant="sm">{t("subscription.allEvents")}</Text>
          </ListItem>
        </UnorderedList>
      );
    } else {
      if (filtersInGroup.length === 0) {
        return (
          <UnorderedList ml={4} aria-label={t<string>(group)}>
            <ListItem>
              <Text variant={"sm"}>{t("subscription.none")}</Text>
            </ListItem>
          </UnorderedList>
        );
      } else {
        return (
          <UnorderedList ml={4} aria-label={t<string>(group)}>
            {eventTypes
              .filter(
                event =>
                  event.group === group &&
                  filtersInGroup.map(eventFilter => eventFilter.filterValue).includes(event.type)
              )
              .map(event => (
                <ListItem key={event.type}>
                  <Text variant={"sm"}>{renderCorrectLanguage(event)}</Text>
                </ListItem>
              ))}
          </UnorderedList>
        );
      }
    }
  };

  const renderLineFilters = (lineFilters: LineFilter[]) => {
    if (lineFilters.length === 0) {
      return (
        <ListItem>
          <Text variant={"sm"}>{t("subscription.none")}</Text>
        </ListItem>
      );
    } else if (lineFilters.some(lineFilter => lineFilter.filterValue.id === "ALL")) {
      return (
        <ListItem>
          <Text variant={"sm"}>{t("subscription.allLines")}</Text>
        </ListItem>
      );
    } else {
      return lineFilters.map(lineFilter => (
        <ListItem key={lineFilter.filterValue.id}>
          <Text variant={"sm"}>
            {lineFilter.filterValue.publicCode} {lineFilter.filterValue.name}
          </Text>
        </ListItem>
      ));
    }
  };

  const renderStationFilters = (stationFilters: StationFilter[]) => {
    if (stationFilters.length === 0) {
      return (
        <ListItem>
          <Text variant="sm">{t("subscription.none")}</Text>
        </ListItem>
      );
    } else {
      const selectedStations = stationFilters
        .map(it => stations.get(it.jbvId) ?? it.jbvId)
        .join(", ");
      return (
        <ListItem>
          <Text variant="sm">{selectedStations}</Text>
        </ListItem>
      );
    }
  };

  const getSubscriptionInformationHeader = (subscription: Subscription) => {
    return (
      <Box>
        <Text variant="sm" fontWeight="bold">
          {subscription.name}
        </Text>
        <Text variant="sm">{t(subscription.notificationChannel)}</Text>
        <Text variant="sm">{formatSubscriptionInterval(subscription)}</Text>
      </Box>
    );
  };

  const getEventFilters = (eventFilters: EventFilter[]) => {
    switch (getEventTypesStatus.status) {
      case "success":
        return (
          <Box>
            <Heading as="h3" variant="sm">
              {t("ACTIONS")}:
            </Heading>
            <UnorderedList ml={4} aria-label={t("ACTIONS")}>
              {eventGroups.map(group => (
                <ListItem key={group}>
                  <Text variant="sm">{t<string>(group)}</Text>
                  {renderEventFilters(group, eventFilters)}
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        );
      case "loading":
      case "notAsked":
        return (
          <DelayedLoading delay={300}>
            <ContentLoader />
          </DelayedLoading>
        );
      case "failure":
        return (
          <FailureAlert
            variant="warning"
            errorMessage={t("errorMessages.eventTypesForSubscription")}
          />
        );
    }
  };

  const getLineFilters = (lineFilters: LineFilter[]) => {
    return (
      <Box>
        <Text variant="sm">{t("LINE")}:</Text>
        <UnorderedList ml={4} aria-label={t("LINE")}>
          {renderLineFilters(lineFilters)}
        </UnorderedList>
      </Box>
    );
  };

  const getStationFilters = (stationFilters: StationFilter[]) => {
    if (getCurrentCountryCode() === "SE") {
      return (
        <Box>
          <Text variant="sm">{t("stationFilter")}</Text>
          <UnorderedList ml={4} aria-label={t("stationFilter")}>
            {renderStationFilters(stationFilters)}
          </UnorderedList>
        </Box>
      );
    }
    return null;
  };

  const getRecipients = (recipients: Recipient[] | undefined) => {
    if (!isAdmin) return null;
    if (recipients === undefined || recipients.length === 0) return null;
    return (
      <Box>
        <Text>{t("emailRecipients")}: </Text>
        {renderRecipients(recipients)}
      </Box>
    );
  };

  const getContent = () => {
    switch (getSubscriptionsStatus.status) {
      case "success":
        return subscription ? (
          <>
            <Stack key={subscription.id} gap={3}>
              {getSubscriptionInformationHeader(subscription)}
              {getEventFilters(subscription.eventFilters)}
              {getLineFilters(subscription.lineFilters)}
              {getStationFilters(subscription.stationFilters)}
              {getRecipients(subscription.recipients)}
            </Stack>
            <Stack mt={4} gap={2}>
              <Button
                variant="secondary"
                size="md"
                leftIcon={<EditOutline24Icon />}
                width="fit-content"
                onClick={() => navigate(`/subscription/edit/${pageId}`)}
              >
                {t("actions.editSubscription")}
              </Button>
              <Button
                variant="tertiary"
                size="md"
                leftIcon={<DeleteOutline18Icon />}
                width="fit-content"
                onClick={() => setOpen(true)}
              >
                {t("actions.delete")}
              </Button>
            </Stack>
          </>
        ) : (
          <DelayedLoading delay={200}>
            <FailureAlert
              variant="error"
              errorMessage={t("errorMessages.errorFindingSubscription")}
            />
          </DelayedLoading>
        );
      case "loading":
      case "notAsked":
        return (
          <DelayedLoading delay={600}>
            <ContentLoader />
          </DelayedLoading>
        );
      case "failure":
        return (
          <FailureAlert
            variant="error"
            errorMessage={t("errorMessages.errorFetchingSubscription")}
          />
        );
    }
  };

  return (
    <MainContainer>
      <Stack gap={3} width="100%">
        <IconButton
          icon={<ArrowLeftOutline24Icon />}
          aria-label={t("actions.goBack")}
          onClick={() => navigate("/")}
          variant="tertiary"
          alignSelf="flex-start"
          mt={2}
        />
        {getContent()}
        <DeleteSubscriptionModal isOpen={isOpen} setOpen={setOpen} subscriptionID={pageId} />
      </Stack>
    </MainContainer>
  );
};

export default SubscriptionInformation;
