import { Recipient as RecipientType, Recipient, User } from "./Types";

export const getCountryCodeAndNationalNumber = (user: User) => {
  let beginning = user.phoneNumber?.substring(0, 4);
  let rest = user.phoneNumber?.substring(4);
  if (beginning && rest && beginning.includes("00")) {
    const countryCode = beginning.replace(/^00/, "+");
    return { countryCode: countryCode, nationalNumber: rest };
  }
  return undefined;
};

export const getPhoneNumberString = (user: User): string => {
  const countryCodeAndNationalNumber = getCountryCodeAndNationalNumber(user);
  if (!countryCodeAndNationalNumber) return user?.phoneNumber ?? "";
  const { countryCode, nationalNumber } = countryCodeAndNationalNumber;
  return countryCode ? `${countryCode} ${nationalNumber}` : nationalNumber ?? "";
};

/**
 * Returns the domain of an email address
 * @param email The email address
 * returns @domain.xyz
 */
export const getDomainFromEmail = (email: string) => "@" + email.toLowerCase().split("@")[1];

/**
 * This regex checks if the email is in a valid format:
 * ^[\w-.]+       : Start with one or more word characters (a-z, A-Z, 0-9, _), dots (.), or hyphens (-)
 * @              : Followed by an "@" symbol
 * ([\w-]+\.)+    : Followed by one or more groups of word characters or hyphens, each followed by a dot (.)
 * [\w-]{2,4}$    : End with a group of 2 to 4 word characters or hyphens
 */
export const isEmailInvalid = (email: string) => !email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);

export const isEmailDuplicate = (email: string, recipients: Recipient[]) =>
  recipients.some(recipient => recipient.email === email);

/**
 * Returns true if any of the recipients are selected
 * @param recipients
 * @param selectedRecipients
 */
export const isAnyRecipientSelected = (
  recipients: RecipientType[],
  selectedRecipients: RecipientType[]
) => recipients.some(recipient => selectedRecipients.some(it => it.id === recipient.id));

/**
 * Returns true if all the recipients are selected
 * @param recipients
 * @param selectedRecipients
 */
export const isEveryRecipientSelected = (
  recipients: RecipientType[],
  selectedRecipients: RecipientType[]
) => recipients.every(recipient => selectedRecipients.some(it => it.id === recipient.id));

/**
 * Groups by domain title with a list of recipients
 * @param recipients
 */
export const groupRecipientsByDomain = (recipients: RecipientType[]) => {
  return recipients.reduce((acc, recipient) => {
    const domain = getDomainFromEmail(recipient.email);

    if (!acc[domain]) acc[domain] = [];

    acc[domain].push(recipient);
    return acc;
  }, {} as Record<string, RecipientType[]>);
};
