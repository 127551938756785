import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PhoneNumberInput,
  Stack,
  StaticAlert,
  Text,
  TextLink,
} from "@vygruppen/spor-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePostRequest } from "../../../api/hooks";
import { getApiUrl } from "../../../api/config";
import { PhoneNumberUpdate, User } from "../../Types";
import { useUser } from "../../contexts/UserContext";
import { isValidPhoneNumber } from "libphonenumber-js";
import { AxiosError } from "axios";
import FailureAlert from "../Alert/FailureAlert";
import { notAsked } from "../../../api/httpRequest";
import { getCountryCodeAndNationalNumber } from "../../Utils";

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegisterPhoneNumberModal: React.FC<Props> = ({ isOpen, setOpen }) => {
  const { user, setUser } = useUser();
  const { t } = useTranslation();

  const {
    postRequestStatus: generateVerificationCodeStatus,
    postRequest: generateVerificationCode,
    setPostRequestStatus: setGenerateVerificationCodeStatus,
  } = usePostRequest<void, string>(`${getApiUrl()}/user/send-verification-code`);
  const {
    postRequestStatus: savePhoneNumberStatus,
    postRequest: savePhoneNumber,
    setPostRequestStatus: setSavePhoneStatus,
  } = usePostRequest<User, PhoneNumberUpdate>(`${getApiUrl()}/user/save-phone-number`);

  const [hasInteracted, setHasInteracted] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState({
    countryCode: "+47",
    nationalNumber: "",
  });
  const [verificationCode, setVerificationCode] = useState("");

  useEffect(() => {
    if (savePhoneNumberStatus.status === "success") {
      setUser(savePhoneNumberStatus.data);
      setPhoneNumber({
        ...phoneNumber,
        nationalNumber: "",
      });
      setVerificationCode("");
      resetRequestStatuses();
      setOpen(false);
    }
  }, [savePhoneNumberStatus.status]);

  useEffect(() => {
    const number = user !== null && getCountryCodeAndNationalNumber(user);
    if (number) setPhoneNumber(number);
  }, [user]);

  const handlePhoneNumberChange = (value: any) => {
    setPhoneNumber(value);
    setHasInteracted(true);
  };

  const getNormalizedPhoneNumber = () => {
    return phoneNumber.countryCode.replace(/^\+/, "00") + phoneNumber.nationalNumber;
  };

  const sendVerificationCodeToPhoneNumber = () => {
    if (user !== null && validPhoneNumber()) {
      // NOTE: This is only valid for countries with 00 as International Dialing Prefix
      const normalizedNumber = getNormalizedPhoneNumber();
      generateVerificationCode(normalizedNumber);
    }
  };

  const validPhoneNumber = () => {
    // Togbrett has 12 digit long numbers - allow 12 digits for norwegian numbers so that personnel can get messages to the tablets
    if (phoneNumber.countryCode === "+47" && phoneNumber.nationalNumber.match(/^\d{12}$/))
      return true;
    return isValidPhoneNumber(phoneNumber.nationalNumber, {
      defaultCallingCode: phoneNumber.countryCode.slice(1),
    });
  };

  const resetRequestStatuses = () => {
    setGenerateVerificationCodeStatus(notAsked());
    setSavePhoneStatus(notAsked());
  };

  const enterPhoneNumber = (
    <>
      <FormControl isInvalid={!validPhoneNumber() && hasInteracted}>
        <PhoneNumberInput value={phoneNumber} onChange={handlePhoneNumberChange} />
        <FormErrorMessage left="6rem">{t("phoneNumber.validatePhoneNumber")}</FormErrorMessage>
      </FormControl>
      <Button
        variant="secondary"
        size="md"
        onClick={sendVerificationCodeToPhoneNumber}
        isLoading={generateVerificationCodeStatus.status === "loading"}
        isDisabled={!validPhoneNumber()}
      >
        {t("phoneNumber.sendVerificationCode")}
      </Button>
    </>
  );

  const errorGeneratingCode = (error: AxiosError) => {
    const errorTextByResponseStatus = () => {
      switch (error.response?.status) {
        case 503:
          return t("phoneNumber.smsSendingIsDisabled");
        default:
          return t("phoneNumber.errorGeneratingCode");
      }
    };

    return <FailureAlert variant="error" errorMessage={errorTextByResponseStatus()} />;
  };

  const enterVerificationCode = (
    <>
      <Text variant="sm" textAlign="start">
        {t("phoneNumber.verificationCodeHasBeenSent", {
          phoneNumber: `${phoneNumber.countryCode}${phoneNumber.nationalNumber}`,
        })}
      </Text>
      <Input
        label={t("phoneNumber.enterVerificationCode")}
        value={verificationCode}
        onChange={e => setVerificationCode(e.target.value)}
      />
      <Button
        variant="secondary"
        size="md"
        onClick={() => {
          savePhoneNumber({
            phoneNumber: getNormalizedPhoneNumber(),
            verificationCode: verificationCode,
          });
        }}
        isLoading={savePhoneNumberStatus.status === "loading"}
      >
        {t("phoneNumber.verifyAndSave")}
      </Button>
    </>
  );

  const errorSavingPhoneNumber = (error: AxiosError) => {
    const errorTextByResponseStatus = () => {
      switch (error.response?.status) {
        case 422:
          return t("phoneNumber.verificationFailed", {
            phoneNumber: `${phoneNumber.countryCode}${phoneNumber.nationalNumber}`,
          });
        default:
          return `${t("phoneNumber.errorSaving")}. `;
      }
    };

    return (
      <>
        <StaticAlert variant="error">
          <Text variant="sm" textAlign="start">
            {errorTextByResponseStatus()}
            {error.response?.status !== 422 && (
              <TextLink variant="secondary" href="mailto:Team-Traffic-Control@vy.no" isExternal>
                {t("contactIT")}
              </TextLink>
            )}
          </Text>
        </StaticAlert>

        <Button variant="secondary" onClick={resetRequestStatuses}>
          {t("phoneNumber.editPhoneNumber")}
        </Button>

        <Button
          variant="secondary"
          onClick={() => {
            resetRequestStatuses();
            sendVerificationCodeToPhoneNumber();
          }}
          isLoading={generateVerificationCodeStatus.status === "loading"}
        >
          {t("phoneNumber.tryAgain")}
        </Button>
      </>
    );
  };

  const getContent = () => {
    switch (generateVerificationCodeStatus.status) {
      case "loading":
      case "notAsked":
        return enterPhoneNumber;
      case "failure":
        return errorGeneratingCode(generateVerificationCodeStatus.error);
      case "success":
        switch (savePhoneNumberStatus.status) {
          case "loading":
          case "notAsked":
            return enterVerificationCode;
          case "failure":
            return errorSavingPhoneNumber(savePhoneNumberStatus.error);
          case "success":
            return null;
        }
    }
  };

  return (
    <Modal
      size="full"
      isOpen={isOpen}
      onClose={() => {
        setOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent borderRadius={0}>
        <ModalHeader padding={4}>
          <Text variant="sm" fontWeight="bold">
            {user?.phoneNumber ? t("phoneNumber.editPhoneNumber") : t("phoneNumber.addPhoneNumber")}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center" mt={2}>
          <Stack gap={2}>{getContent()}</Stack>
        </ModalBody>
        <ModalFooter justifyContent="center" gap={2}></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RegisterPhoneNumberModal;
