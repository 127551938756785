import { useTranslation } from "react-i18next";
import { DaysOfWeek, Subscription } from "../Types";

const getCombinedWeekdays = (daysOfWeek: DaysOfWeek, t: (key: string) => string) => {
  const days = Object.entries(daysOfWeek);
  const combinedWeekdays = [];

  let start = 0;

  while (start < days.length) {
    let end = start + 1;
    const startDayIncluded = days[start][1];
    let endDayIncluded = (end: number) => days[end][1];
    if (startDayIncluded) {
      while (end < days.length && endDayIncluded(end)) {
        end++;
      }
      const consecutiveDays = days.slice(start, end);
      if (consecutiveDays.length > 2) {
        const firstDay = consecutiveDays[0][0];
        const lastDay = consecutiveDays[consecutiveDays.length - 1][0];
        combinedWeekdays.push(
          `${t(`weekday.${firstDay}_short`)} - ${t(`weekday.${lastDay}_short`)}`
        );
      } else {
        consecutiveDays.forEach(([day]) => {
          combinedWeekdays.push(t(`weekday.${day}_short`));
        });
      }
      start = end;
    } else {
      start++;
    }
  }
  return combinedWeekdays.join(", ");
};

const getShortTime = (time: string) => time.substring(0, 5);

const UseFormatSubscriptionData = () => {
  const { t } = useTranslation();

  const formatSubscriptionInterval = (subscription: Subscription) => {
    const weekdays = getCombinedWeekdays(subscription.notificationInterval.daysOfWeek, t);
    const startTime = getShortTime(subscription.notificationInterval.start);
    const endTime = getShortTime(subscription.notificationInterval.end);
    return `${weekdays}, ${startTime} - ${endTime}`;
  };

  return {
    formatSubscriptionInterval,
  };
};

export default UseFormatSubscriptionData;
