import React from "react";
import { createRoot } from "react-dom/client";
import "index.css";
import "./i18n";
import App from "App";
import { Language, SporProvider } from "@vygruppen/spor-react";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import { getCurrentLanguage } from "./environment";
import { UserProvider } from "./shared/contexts/UserContext";
import { AuthProvider } from "./shared/contexts/AuthContext";

const root = createRoot(document.getElementById("root")!);
document.title = getCurrentLanguage() === Language.NorwegianBokmal ? "Varslinger" : "Notiser";

root.render(
  <React.StrictMode>
    <SporProvider>
      <UserProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </UserProvider>
    </SporProvider>
  </React.StrictMode>
);

// Registers serviceWorker to enable offline usage.
// Possible to unregister by switching to .unregister()
serviceWorkerRegistration.register();
