import { Translation } from "../i18n-resources";

export const sv: Translation = {
  applicationName: "Notiser",
  title: "Titel",
  profile: "Profil",
  addingUser: "Lägger till dig som ny användare",
  contactIT: "Kontakta IT om felet kvarstår",
  errorAddingUser: "Kunde inte lägga till dig som ny användare.",
  loadingUser: "Hämtar användardata",
  logIn: "Logga in",
  logInExternal: "Logga in för externa",
  logInExternalPrompt:
    "Kontakta Team-Traffic-Control@vy.no om du har problem med att logga in eller saknar åtkomst.",
  logInExternalEmailLabel: "E-post",
  logInExternalPasswordLabel: "Lösenord",
  changePasswordError:
    "Kunde inte sätta ett nytt lösenord. Försök att gå tillbaka till inloggningssidan och försök igen.",
  externalLogInError:
    "Det gick inte att logga in, kontrollera att ditt e-postadress och lösenord är korrekta.",
  externalLogInWrongPassword: "Fel lösenord eller användarnamn.",
  externalLogInExceededAttempts:
    "Du har gjort för många försök att logga in med fel lösenord, försök igen senare.",
  logoutError: "Kunde inte logga ut.",
  loginError: "Kunde inte logga inn.",
  newPasswordInputLabel: "Nytt lösenord",
  confirmPasswordInputLabel: "Bekreftä nytt lösenord",
  invalidPassword:
    "Ogiltigt lösenord, lösenordet måste innehålla minst 12 tecken, en liten bokstav, en stor bokstav, en siffra och ett specialtecken.",
  invalidCode: "Den kod du angav var felaktig, verifiera att du har rätt kod och försök igen.",
  resetPasswordError:
    "Det gick inte att återställa ditt lösenord, försök igen senare. Kontakta Team-Traffic-Control@vy.no om felet kvarstår.",
  notMatchingPasswords: "Lösenorden måste vara desamma.",
  codeMustHaveLengthSix: "Koden måste vara på 6 tecken.",
  codeHelperText: "Skriv in koden du har fått via mejl.",
  setNewPassword: "Ange nytt lösenord",
  setNewPasswordPrompt: "När du loggar in för första gången måste du skapa ett nytt lösenord.",
  administratorPasswordReset:
    "En administratör har återställt ditt lösenord åt dig. Om du anser att detta är felaktigt, kontakta Team-Traffic-Control@vy.no. Du bör ha mottagit ett e-postmeddelande med kod.",
  logOut: "Logga ut",
  "logOut?": "Vill du logga ut från Notiser?",
  noAccess: "Du har inte åtkomst",
  welcomeUser: "Välkommen som ny användare! Du kommer snart att skickas vidare in i systemet",
  noSubscription: "Inga fasta notiser",
  noSubscriptionText:
    "Ställ in en notis för att få meddelanden om händelser i tågtrafiken för specifika tåg eller sträckor.",
  recurringSubscription: "Mina fasta notiser",
  introPrompt:
    "Här kan du ställa in notiser för att få information när det händer saker i trafiken.",
  subscriptionList: {
    addSubscription: "Lägg till",
    newSubscription: "Skapa ny notis",
    loading: "Hämtar notiser",
  },
  subscription: {
    newSubscription: "Ny notis",
    newSubscriptionPrompt:
      "Välj vilka typer av händelser du vill få notiser om. Du kan enkelt ändra detta senare om du vill.",
    titlePrompt: "Lägg till titel för notisen.",
    allEvents: "Alla händelser",
    allLines: "Alla tågserier/linjer",
    none: "Inget valt",
  },
  subscriptionPreference: {
    newSubscriptionFilters:
      "Du kan specificera notiserna genom att markera vilka stationer och/eller tågserier/linjer du vill ha notiser för.",
    newSubscriptionTime: "Jag vill ha notiser om händelser under tidsintervallet",
    adminNewSubscriptionTime: "Jag vill ställa in en varning för händelser under perioden",
    newSubscriptionChannel: "Jag vill ha notiser via",
    subscriptionRecipients: "Välj mottagare",
    pauseOption: "Notiser kan pausas tillfälligt från startsidan",
  },
  phoneNumber: {
    phoneNumber: "Telefonnummer",
    addPhoneNumber: "Registrera telefonnummer",
    addPhoneNumberText: "Om du vill ha notiser via SMS",
    registerPhoneNumberHere: "Registrera telefonnummer här",
    couldNotFindNumber: "Vi kunde inte hitta ditt telefonnummer i våra register.",
    validatePhoneNumber: "Du måste fylla i ett telefonnummer",
    sendVerificationCode: "Skicka verifieringskod",
    verificationCodeHasBeenSent: "En verifieringskod har skickats till {{phoneNumber}}",
    enterVerificationCode: "Ange verifieringskod",
    verifyAndSave: "Verifiera telefonnummer",
    verificationFailed: "Verifiering misslyckades för nummer {{phoneNumber}}",
    errorSaving: "Något gick fel när telefonnumret skulle sparas",
    editPhoneNumber: "Redigera telefonnummer",
    tryAgain: "Försök igen",
    smsSendingIsDisabled: "SMS-sändning är inte tillgänglig för tillfället. Försök igen senare.",
    errorGeneratingCode: "Något gick fel när verifieringskoden skulle skickas.",
  },
  userInfo: {
    notificationGetsSentTo: "Notiser skickas till",
    email: "Epost",
  },
  recipient: {
    noRecipients: "Du har inga mottagare. Lägg till en mottagare nedan för att se alternativ.",
    addRecipient: "Lägg till ny mottagare",
    addRecipientButtonText: "Ny mottagare",
    editRecipientInputLabel: "Ny e-postadress",
    addRecipientInputLabel: "E-postadress",
    addRecipientError: "Det gick inte att spara mottagaren, något gick fel.",
    deleteRecipientError: "Det gick inte att ta bort mottagaren",
    duplicateRecipientEmailError: "Mottagaren finns redan",
    invalidRecipientEmailError:
      "Ogiltig e-postadress. Vänligen ange en giltig e-postadress (t.ex. olanordmann@vy.se).",
    editRecipientError: "Kunde inte spara ändringar",
    confirmDeleteTitle: "Är du säker på att du vill ta bort mottagaren?",
    confirmDeleteText:
      "När du tar bort {{email}} kommer mottagaren tas bort från alla meddelanden du har skapat. Om du bara vill ta bort användaren från detta meddelande, avmarkera bara rutan bredvid e-postadressen.",
    closeConfirmDelete: "Stäng",
    confirmDeleteButton: "Bekräfta borttagning",
    editEmailAriaLabel: "Redigera e-post",
    deleteEmailAriaLabel: "Ta bort e-post",
    saveChangesAriaLabel: "Spara ändringar",
    cancelWithoutSaveAriaLabel: "Avsluta redigering utan att spara",
  },
  actions: {
    ok: "Ok",
    next: "Nästa",
    back: "Tillbaka",
    save: "Spara",
    delete: "Radera",
    goBack: "Gå tillbaka till startsidan",
    edit: "Redigera",
    editSubscription: "Redigera notis",
    deleteSubscription: "Radera notis",
    cancel: "Avbryt",
    "sure?": "Är du säker på att du vill radera notisen?",
    selectAll: "Välj alla",
  },
  weekday: {
    pickAll: "välj alla",
    monday: "måndag",
    tuesday: "tisdag",
    wednesday: "onsdag",
    thursday: "torsdag",
    friday: "fredag",
    saturday: "lördag",
    sunday: "söndag",
    monday_short: "mån",
    tuesday_short: "tis",
    wednesday_short: "ons",
    thursday_short: "tor",
    friday_short: "fre",
    saturday_short: "lör",
    sunday_short: "sön",
  },
  TRAIN: "Tåg",
  INFRASTRUCTURE: "Infrastruktur",
  STATION: "Station",
  ALTERNATIVE_TRANSPORT: "Alternativ transport",
  LINE: "Tågserier/linjer",
  STRETCH: "Sträckningar",
  ACTIONS: "Händelser",
  EMAIL: "E-postnotis",
  SMS: "SMS-notis",
  EMAIL_AND_SMS: "SMS- och epostnotis",
  emailRecipients: "E-post mottagare",
  stationFilter: "Stationer",
  stationSelect: {
    selectStation: "Välj station",
    noneSelected:
      "Inga stationer har valts ut ännu. Välj stationer genom att söka efter stationsnamn i inmatningsfältet ovan",
    selected: "valt",
    removeStation: "Ta bort stationen",
    filterExplanation:
      "Du kan ange stationer för vilka du vill få notiser om något händer på någon av stationerna.",
  },
  errorMessages: {
    timeErrorMessage: "Tid från måste vara före tid till",
    somethingWentWrong: "Något gick fel",
    failedToSaveSubscription: "Misslyckades med att spara notisen",
    errorFetchingSubscriptions: "Misslyckades med att hämta dina notiser.",
    errorFetchingSubscription: "Misslyckades med att hämta notisen.",
    errorFindingSubscription: "Misslyckades med att hitta notisen.",
    errorUpdatingSubscription: "Misslyckades med att uppdatera din notis",
    errorFetchingEventTypes: "Misslyckades med att hämta händelser.",
    errorFetchingLines: "Misslyckades med att hämta linjer.",
    eventTypesForSubscription: "Misslyckades med att hämta händelser för notisen.",
    errorDeletingSubscription: "Misslyckades med att radera notisen.",
    errorFetchingRecipients: "Misslyckades med att hämta mottagare",
  },
  to: "Till",
  from: "Från",
  searchRecipientInputLabel: "Sök efter mottagare",
  selected: "utvalda",
};
