import React, { FC, useEffect, useState } from "react";
import { useDeleteRequest } from "../../api/hooks";
import { getApiUrl } from "../../api/config";
import { RecipientCheckBox } from "./RecipientCheckBox";
import { EditRecipient } from "./EditRecipient";
import { ClosableAlert } from "@vygruppen/spor-react";
import { useTranslation } from "react-i18next";

interface Props {
  id: number;
  email: string;
  updateRecipient: (id: number, email: string) => void;
  removeRecipient: (id: number) => void;
  isChecked: boolean;
  toggleRecipient: (id: number, email: string) => void;
}

export const Recipient: FC<Props> = ({
  id,
  email,
  updateRecipient,
  removeRecipient,
  isChecked,
  toggleRecipient,
}) => {
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  const { deleteRequest: deleteRecipient, deleteRequestStatus: deleteRecipientStatus } =
    useDeleteRequest<String>(`${getApiUrl()}/recipients/${id}`);

  useEffect(() => {
    if (deleteRecipientStatus.status === "success") {
      removeRecipient(id);
    }
  }, [deleteRecipientStatus]);

  if (edit)
    return (
      <EditRecipient id={id} email={email} updateRecipient={updateRecipient} setEdit={setEdit} />
    );

  return (
    <>
      <RecipientCheckBox
        id={id}
        email={email}
        isChecked={isChecked}
        toggleRecipient={toggleRecipient}
        deleteRecipient={deleteRecipient}
        setEdit={setEdit}
      />
      {deleteRecipientStatus.status === "failure" && (
        <ClosableAlert variant="error">{t("recipient.deleteRecipientError")}</ClosableAlert>
      )}
    </>
  );
};
