import {
  Button,
  Checkbox,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@vygruppen/spor-react";
import { DeleteOutline24Icon, EditOutline24Icon } from "@vygruppen/spor-icon-react";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import styled from "@emotion/styled";
import { useUser } from "../../shared/contexts/UserContext";
import { useTranslation } from "react-i18next";

// We have to set the width for the label here to make text-overflow work
const CustomCheckBox = styled(Checkbox)`
  .chakra-checkbox__label {
    width: 80%;
    flex: 1;
  }
`;

interface Props {
  id: number;
  email: string;
  isChecked: boolean;
  toggleRecipient: (id: number, email: string) => void;
  deleteRecipient: () => void;
  setEdit: Dispatch<SetStateAction<boolean>>;
}

export const RecipientCheckBox: FC<Props> = ({
  id,
  email,
  isChecked,
  toggleRecipient,
  deleteRecipient,
  setEdit,
}) => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const { user } = useUser();
  const { t } = useTranslation();

  // Not possible to edit or delete your own email
  const editable = user?.email !== email;

  const confirmDeleteModal = () => {
    return (
      <>
        <Modal isOpen={showConfirmDeleteModal} onClose={() => setShowConfirmDeleteModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t("recipient.confirmDeleteTitle")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>{t("recipient.confirmDeleteText", { email: email })}</Text>
            </ModalBody>
            <ModalFooter>
              <Button variant="tertiary" mr={3} onClick={() => setShowConfirmDeleteModal(false)}>
                {t("recipient.closeConfirmDelete")}
              </Button>
              <Button
                variant="primary"
                mr={3}
                onClick={() => {
                  deleteRecipient();
                  setShowConfirmDeleteModal(false);
                }}
              >
                {t("recipient.confirmDeleteButton")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  return (
    <Flex justifyContent="space-between" minWidth={0} gap={2}>
      {confirmDeleteModal()}
      <CustomCheckBox
        isChecked={isChecked}
        value={email}
        onChange={event => toggleRecipient(id, email)}
        minWidth={0}
      >
        <Text whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" flex={1}>
          {email}
        </Text>
      </CustomCheckBox>
      {editable && (
        <HStack flexShrink={0}>
          <IconButton
            size={"xs"}
            variant={"ghost"}
            icon={<EditOutline24Icon />}
            aria-label={t("recipient.editEmailAriaLabel")}
            onClick={() => setEdit(true)}
          />
          <IconButton
            size={"xs"}
            variant={"ghost"}
            icon={<DeleteOutline24Icon />}
            aria-label={t("recipient.deleteEmailAriaLabel")}
            onClick={() => setShowConfirmDeleteModal(true)}
          />
        </HStack>
      )}
    </Flex>
  );
};
