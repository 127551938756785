import {
  Accordion,
  Box,
  Checkbox,
  CheckboxGroup,
  ContentLoader,
  Expandable,
  Text,
} from "@vygruppen/spor-react";
import { Recipient as RecipientType } from "../../shared/Types";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useGetRequest } from "../../api/hooks";
import { getApiUrl } from "../../api/config";
import { useUser } from "../../shared/contexts/UserContext";
import { Recipient } from "./Recipient";
import { useTranslation } from "react-i18next";
import { AddNewRecipient } from "./AddNewRecipient";
import {
  groupRecipientsByDomain,
  isAnyRecipientSelected,
  isEveryRecipientSelected,
} from "../../shared/Utils";

interface Props {
  selectedRecipients: RecipientType[];
  removeSelectedRecipient: (id: number) => void;
  toggleRecipient: (id: number, email: string) => void;
  query: string;
}

export const RecipientList: FC<Props> = ({
  selectedRecipients,
  removeSelectedRecipient,
  toggleRecipient,
  query,
}) => {
  const [recipients, setRecipients] = useState<RecipientType[]>([]);
  const { t } = useTranslation();
  const { isAdmin, user } = useUser();
  const { getRequest: getRecipients, getRequestStatus: getRecipientsStatus } = useGetRequest<
    RecipientType[]
  >(`${getApiUrl()}/recipients`);

  useEffect(() => {
    if (isAdmin) {
      switch (getRecipientsStatus.status) {
        case "notAsked":
          getRecipients();
          break;
        case "success":
          const sortedRecipients = getRecipientsStatus.data.sort((a, b) => a.id - b.id);
          if (user) sortedRecipients.splice(0, 0, { email: user.email, id: user.id });
          setRecipients(sortedRecipients);
          break;
      }
    }
  }, [isAdmin, user, getRecipientsStatus]);

  const addRecipient = (id: number, email: string) => {
    setRecipients(previousRecipients => {
      return [...previousRecipients, { email, id }];
    });
  };

  const removeRecipient = (id: number) => {
    setRecipients(previousRecipients => {
      return previousRecipients.filter(recipient => recipient.id !== id);
    });
    removeSelectedRecipient(id);
  };

  const updateRecipient = (id: number, email: string) => {
    setRecipients(previousRecipients => {
      return previousRecipients.map(recipient => {
        if (recipient.id === id) return { email, id };
        return recipient;
      });
    });
  };

  const groupedRecipients = groupRecipientsByDomain(recipients);

  const getDomainTitleWithCount = (domain: string) => {
    const selectedCount = selectedRecipients.filter(sr => sr.email.includes(domain)).length;
    return `${domain} ${selectedCount > 0 ? `(${selectedCount} ${t("selected")})` : ""}`;
  };

  const handleSelectAllButton = (domain: string, allSelected: boolean) => {
    const recipientList = groupedRecipients[domain];

    recipientList.forEach(recipient => {
      const isRecipientSelected = selectedRecipients.some(it => it.id === recipient.id);
      if (allSelected !== isRecipientSelected) toggleRecipient(recipient.id, recipient.email);
    });
  };

  const filterBySearchQuery = query
    ? Object.entries(groupedRecipients).reduce((acc, [group, recipients]) => {
        const filteredRecipients = recipients.filter(recipient =>
          recipient.email.toLowerCase().includes(query.toLowerCase())
        );

        if (filteredRecipients.length > 0) {
          acc[group] = filteredRecipients;
        }
        return acc;
      }, {} as Record<string, RecipientType[]>)
    : groupedRecipients;

  if (getRecipientsStatus.status === "failure") {
    return (
      <Box>
        <Text>{t("errorMessages.errorFetchingRecipients")}</Text>
      </Box>
    );
  }

  if (getRecipientsStatus.status === "loading") return <ContentLoader />;

  return (
    <>
      {recipients.length === 0 && <Text>{t("recipient.noRecipients")}</Text>}
      <Accordion>
        {Object.entries(filterBySearchQuery)
          .sort()
          .map(([domain, recipients]) => {
            return (
              <Expandable
                key={domain}
                variant="ghost"
                defaultOpen={isAnyRecipientSelected(recipients, selectedRecipients)}
                title={getDomainTitleWithCount(domain)}
                allowToggle
              >
                <CheckboxGroup
                  direction="column"
                  value={selectedRecipients.map(recipient => recipient.email)}
                >
                  <Checkbox
                    isChecked={isEveryRecipientSelected(recipients, selectedRecipients)}
                    onChange={e => handleSelectAllButton(domain, e.target.checked)}
                  >
                    {t("actions.selectAll")}
                  </Checkbox>
                  {recipients.map(({ email, id }) => (
                    <Recipient
                      key={id}
                      email={email}
                      id={id}
                      updateRecipient={updateRecipient}
                      removeRecipient={removeRecipient}
                      isChecked={selectedRecipients.some(recipient => recipient.id === id)}
                      toggleRecipient={toggleRecipient}
                    />
                  ))}
                </CheckboxGroup>
              </Expandable>
            );
          })}
      </Accordion>
      <AddNewRecipient addRecipient={addRecipient} recipients={recipients} />
    </>
  );
};
