import { ClosableAlert, IconButton, Input, Stack } from "@vygruppen/spor-react";
import { CheckmarkOutline24Icon, CloseOutline24Icon } from "@vygruppen/spor-icon-react";
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { usePatchRequest } from "../../api/hooks";
import { Recipient as RecipientType } from "../../shared/Types";
import { getApiUrl } from "../../api/config";
import { useTranslation } from "react-i18next";

interface Props {
  id: number;
  email: string;
  updateRecipient: (id: number, email: string) => void;
  setEdit: Dispatch<SetStateAction<boolean>>;
}

export const EditRecipient: FC<Props> = ({ id, email, updateRecipient, setEdit }) => {
  const [newEmail, setNewEmail] = useState(email);
  const { t } = useTranslation();
  const { patchRequest: saveRecipient, patchRequestStatus: saveRecipientStatus } = usePatchRequest<
    RecipientType,
    RecipientType
  >(`${getApiUrl()}/recipients`);

  useEffect(() => {
    if (saveRecipientStatus.status === "success") {
      updateRecipient(id, newEmail);
      setEdit(false);
    }
  }, [saveRecipientStatus]);

  const saveEmail = (newEmailValue: string) => {
    if (newEmailValue === email) return setEdit(false);
    saveRecipient({ id: id, email: newEmailValue });
  };

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Input
          label={t("recipient.editRecipientInputLabel")}
          value={newEmail}
          onChange={e => {
            setNewEmail(e.target.value);
          }}
        ></Input>
        <IconButton
          size="xs"
          variant="ghost"
          icon={<CheckmarkOutline24Icon />}
          aria-label={t("recipient.saveChangesAriaLabel")}
          onClick={() => saveEmail(newEmail)}
        />
        <IconButton
          size="xs"
          variant="ghost"
          icon={<CloseOutline24Icon />}
          aria-label={t("recipient.cancelWithoutSaveAriaLabel")}
          onClick={() => setEdit(false)}
        />
      </Stack>
      {saveRecipientStatus.status === "failure" && (
        <ClosableAlert variant="error">{t("recipient.editRecipientError")}</ClosableAlert>
      )}
    </>
  );
};
