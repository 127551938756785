import axios, { AxiosRequestConfig } from "axios";

export const get = async <ResponseDataType>(
  url: string,
  config: AxiosRequestConfig = {}
): Promise<ResponseDataType> => {
  const response = await axios.get<ResponseDataType>(url, config);
  return response.data;
};

export const post = async <RequestDataType, ResponseDataType>(
  url: string,
  data: RequestDataType,
  config: AxiosRequestConfig = {}
): Promise<ResponseDataType> => {
  const response = await axios.post<ResponseDataType>(url, data, config);
  return response.data;
};

export const put = async <RequestDataType, ResponseDataType>(
  url: string,
  data: RequestDataType,
  config: AxiosRequestConfig
): Promise<ResponseDataType> => {
  const response = await axios.put<ResponseDataType>(url, data, config);
  return response.data;
};

export const remove = async <ResponseDataType>(
  url: string,
  config: AxiosRequestConfig
): Promise<ResponseDataType> => {
  const response = await axios.delete<ResponseDataType>(url, config);
  return response.data;
};
