import React, { useState } from "react";
import { Button, FormControl, Input, PasswordInput, Text } from "@vygruppen/spor-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LoginContainer } from "./LoginContainer";
import { AuthResult, useAuth } from "../../shared/contexts/AuthContext";
import FailureAlert from "../../shared/components/Alert/FailureAlert";

export const LoginExternal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { signIn } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleLogin = async () => {
    if (errorMessage) setErrorMessage(undefined);
    setIsLoading(true);
    const result = await signIn(email, password);
    setIsLoading(false);
    if (!result.success && result.message === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED")
      navigate("/change-password", { state: { email: email } });
    else if (!result.success && result.message === "RESET_PASSWORD") {
      navigate("/reset-password", { state: { email: email } });
    } else if (result.success) {
      navigate("/login-redirect");
    } else {
      handleLoginError(result.message);
    }
  };

  const handleLoginError = (message: AuthResult["message"]) => {
    if (message === "SignInFailedWrongPassword") {
      setErrorMessage(t("externalLogInWrongPassword"));
    } else if (message === "SignInFailedExceededAttempts") {
      setErrorMessage(t("externalLogInExceededAttempts"));
    } else {
      setErrorMessage(t("externalLogInError"));
    }
  };

  return (
    <LoginContainer>
      <Text as="h1" variant="md" fontWeight="bold" mb={1}>
        {t("applicationName")}
      </Text>
      <Text as="h2" variant="sm" mb={4}>
        {t("logInExternalPrompt")}
      </Text>
      {errorMessage && <FailureAlert variant="error" errorMessage={errorMessage} />}
      <FormControl>
        <Input
          label={t("logInExternalEmailLabel")}
          value={email}
          onChange={e => setEmail(e.target.value)}
          backgroundColor="none"
        />
      </FormControl>
      <FormControl mt={1.5} mb={3}>
        <PasswordInput
          label={t("logInExternalPasswordLabel")}
          value={password}
          onChange={e => setPassword(e.target.value)}
          backgroundColor="none"
        />
      </FormControl>
      <Button variant="primary" size="lg" width="100%" onClick={handleLogin} isLoading={isLoading}>
        {t("logIn")}
      </Button>
    </LoginContainer>
  );
};
