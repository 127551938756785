/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useGetRequest } from "../../api/hooks";
import { getApiUrl } from "../../api/config";

type Attribute = {
  [key: string]: number | string | number[] | string[] | boolean;
};

type FeatureFlag = {
  name: string;
  enabled: boolean;
  attributes: Attribute[];
};

const FlagContext = React.createContext<FeatureFlag[]>([]);

export function FeatureFlagProvider({ children }: { children: React.ReactNode }) {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([]);

  const { getRequestStatus: remoteData, getRequest } = useGetRequest<FeatureFlag[]>(
    `${getApiUrl()}/features`
  );

  useEffect(() => {
    // Uncomment if feature flags are needed and the endpoint exists in the backend
    // if (remoteData.status === "notAsked") getRequest();
  }, [remoteData.status]);

  useEffect(() => {
    if (remoteData.status === "success") {
      setFeatureFlags(remoteData.data);
    }
  }, [remoteData.status]);

  return <FlagContext.Provider value={featureFlags}>{children}</FlagContext.Provider>;
}

export function useFeatureFlag(name: string): FeatureFlag {
  const flags = React.useContext(FlagContext);
  return (
    (flags.length ? flags.find(flag => flag.name === name) : null) ?? {
      name,
      enabled: false,
      attributes: [],
    }
  );
}
