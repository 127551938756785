import appConfig from "appConfig.json";
import { Language } from "@vygruppen/spor-react";

export enum Environment {
  DEVELOPMENT = "DEV",
  TEST = "TEST",
  STAGE = "STAGE",
  PRODUCTION = "PROD",

  DEVELOPMENT_SE = "DEV_SE",
  TEST_SE = "TEST_SE",
  STAGE_SE = "STAGE_SE",
  PRODUCTION_SE = "PROD_SE",
}

export const getCurrentEnvironment = (): Environment => {
  if (
    process.env.NODE_ENV === "development" ||
    window.location.origin.includes("localhost") ||
    window.location.origin.includes("127.0.0.1")
  ) {
    // Swap with DEVELOPMENT_SE for swedish language while developing locally
    return Environment.DEVELOPMENT;
  }

  if (window.location.origin.endsWith(appConfig.environments.TEST)) {
    return Environment.TEST;
  }

  if (window.location.origin.endsWith(appConfig.environments.STAGE)) {
    return Environment.STAGE;
  }

  if (window.location.origin.endsWith(appConfig.environments.PROD)) {
    return Environment.PRODUCTION;
  }

  if (window.location.origin.endsWith(appConfig.environments.TEST_SE)) {
    return Environment.TEST_SE;
  }

  if (window.location.origin.endsWith(appConfig.environments.STAGE_SE)) {
    return Environment.STAGE_SE;
  }

  if (window.location.origin.endsWith(appConfig.environments.PROD_SE)) {
    return Environment.PRODUCTION_SE;
  }

  throw new Error("Fant ikke riktig miljø");
};

export const getCurrentLanguage = (): Language => {
  switch (getCurrentEnvironment()) {
    case Environment.DEVELOPMENT:
    case Environment.TEST:
    case Environment.STAGE:
    case Environment.PRODUCTION:
      return Language.NorwegianBokmal;

    case Environment.DEVELOPMENT_SE:
    case Environment.TEST_SE:
    case Environment.STAGE_SE:
    case Environment.PRODUCTION_SE:
      return Language.Swedish;

    default:
      return Language.NorwegianBokmal;
  }
};

export const getCurrentCountryCode = (): string => {
  switch (getCurrentEnvironment()) {
    case Environment.DEVELOPMENT:
    case Environment.TEST:
    case Environment.STAGE:
    case Environment.PRODUCTION:
      return "NO";

    case Environment.DEVELOPMENT_SE:
    case Environment.TEST_SE:
    case Environment.STAGE_SE:
    case Environment.PRODUCTION_SE:
      return "SE";

    default:
      throw new Error("Klarte ikke å utlede en landkode");
  }
};
