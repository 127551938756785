import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  Language,
  Stack,
  StaticAlert,
  Text,
} from "@vygruppen/spor-react";
import {
  AddOutline24Icon,
  CheckmarkOutline30Icon,
  CloseOutline30Icon,
} from "@vygruppen/spor-icon-react";
import { getCurrentLanguage } from "../../environment";
import React, { FC, useEffect, useState } from "react";
import { usePostRequest } from "../../api/hooks";
import { Recipient } from "../../shared/Types";
import { getApiUrl } from "../../api/config";
import { useTranslation } from "react-i18next";
import { isEmailDuplicate, isEmailInvalid } from "../../shared/Utils";

interface Props {
  recipients: Recipient[];
  addRecipient: (id: number, email: string) => void;
}

interface CreateRecipientRequest {
  email: string;
  language: string;
}

export const AddNewRecipient: FC<Props> = ({ addRecipient, recipients }) => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showAddForm, setShowAddForm] = useState(false);
  const { t } = useTranslation();
  const { postRequest: saveRecipient, postRequestStatus: saveRecipientStatus } = usePostRequest<
    Recipient,
    CreateRecipientRequest
  >(`${getApiUrl()}/recipients`);

  const validateAndSubmitEmail = () => {
    if (isEmailDuplicate(email, recipients)) {
      setErrorMessage(t("recipient.duplicateRecipientEmailError"));
      return;
    }
    if (isEmailInvalid(email)) {
      setErrorMessage(t("recipient.invalidRecipientEmailError"));
      return;
    }

    saveRecipient({
      email: email,
      language: getCurrentLanguage() === Language.NorwegianBokmal ? "NORWEGIAN" : "SWEDISH",
    });
  };

  useEffect(() => {
    if (saveRecipientStatus.status === "success") {
      addRecipient(saveRecipientStatus.data.id, saveRecipientStatus.data.email);
      setEmail("");
      setShowAddForm(false);
    }
  }, [saveRecipientStatus]);

  if (showAddForm) {
    return (
      <>
        <Text mt={4} mb={1} fontWeight="bold">
          {t("recipient.addRecipient")}
        </Text>
        {saveRecipientStatus.status === "failure" && (
          <>
            <StaticAlert mt={2.5} mb={2.5} variant="error">
              {t("recipient.addRecipientError")}
            </StaticAlert>
          </>
        )}
        <Stack flexDirection="row">
          <FormControl isInvalid={!!errorMessage}>
            <Input
              label={t("recipient.addRecipientInputLabel")}
              value={email}
              onChange={event => {
                setEmail(event.target.value);
                setErrorMessage("");
              }}
            />
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
          </FormControl>
          <Box display="flex" flexDirection="row" alignItems="center" gap={3} ml="0.6rem">
            <IconButton
              size="xs"
              variant="ghost"
              icon={<CheckmarkOutline30Icon />}
              aria-label={t("recipient.saveChangesAriaLabel")}
              onClick={() => validateAndSubmitEmail()}
            />
            <IconButton
              size="xs"
              variant="ghost"
              icon={<CloseOutline30Icon />}
              aria-label={t("recipient.cancelWithoutSaveAriaLabel")}
              onClick={() => setShowAddForm(false)}
            />
          </Box>
        </Stack>
      </>
    );
  } else {
    return (
      <Box display="flex" justifyContent="center">
        <Button
          mt={3}
          leftIcon={<AddOutline24Icon />}
          variant="secondary"
          onClick={() => setShowAddForm(true)}
        >
          {t("recipient.addRecipientButtonText")}
        </Button>
      </Box>
    );
  }
};
