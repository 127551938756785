import { get } from "api/apiClient";
import { getApiUrl } from "api/config";

const baseUrl = getApiUrl();

interface LoginSettings {
  notificationAuthUrl: string;
  notificationClientId: string;
  notificationUserPoolId: string;
}

export const getLoginData = async (): Promise<LoginSettings> => {
  return get<LoginSettings>(`${baseUrl}/app-data`);
};
